import Actions from "../actions/apps";
import { call, put } from "redux-saga/effects";

export const postApp = requestModule =>
  function* postAppSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `apps`,
        method: "POST",
        data: action.appData
      });

      if (action.callback) {
        yield call(action.callback, { success: true, data: req.data });
      }

    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const getAllApps = requestModule =>
  function* getAllAppsSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `apps`,
        method: "GET"
      });

      yield put(Actions.addAllAppsToList(req.data.apps));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }

    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };