// import * as TYPES from "../actions/types";
const initialState = {};

const reducer = (state = initialState, action) => {
  // if(action.type === TYPES.MODELS_GET_POST_URL) {
  //   return {
  //     ...state
  //   }
  // }
  return state
};

export default reducer;
