//Collections
export const COLLECTIONS_BUNDLE_FETCH = "COLLECTIONS_BUNDLE_FETCH"; 
export const COLLECTIONS_SET_COLLECTION_LIST = "COLLECTIONS_SET_COLLECTION_LIST"; 
export const COLLECTIONS_SET_RETRIEVED = "COLLECTIONS_SET_RETRIEVED"; 
export const COLLECTIONS_FETCH_ALL = "COLLECTIONS_FETCH_ALL"; 
export const COLLECTIONS_FETCH_A_COLLECTION = "COLLECTIONS_FETCH_A_COLLECTION"; 
export const COLLECTIONS_PATCH_COLLECTION_META = 'COLLECTIONS_PATCH_COLLECTION_META';
export const COLLECTIONS_SET_COLLECTION_META = 'COLLECTIONS_SET_COLLECTION_META';
export const COLLECTIONS_GET_MODEL_URL = 'COLLECTIONS_GET_MODEL_URL';
export const COLLECTIONS_SET_MODEL_URL = 'COLLECTIONS_SET_MODEL_URL';
export const COLLECTIONS_PATCH_COLLECTION_DATA = 'COLLECTIONS_PATCH_COLLECTION_DATA';
export const COLLECTIONS_SET_COLLECTION_DATA = 'COLLECTIONS_SET_COLLECTION_DATA';
export const COLLECTIONS_SET_OBJECT_LIST = 'COLLECTIONS_SET_OBJECT_LIST';
export const COLLECTIONS_FETCH_A_COLLECTION_DATA = 'COLLECTIONS_FETCH_A_COLLECTION_DATA';
export const COLLECTIONS_SET_A_COLLECTIONS_LABELS_GROUPS = 'COLLECTIONS_SET_A_COLLECTIONS_LABELS_GROUPS';
export const COLLECTIONS_UPDATE_COLLECTION_MODEL = 'COLLECTIONS_UPDATE_COLLECTION_MODEL'
export const COLLECTIONS_PUT_COLLECTION_MODEL = 'COLLECTIONS_PUT_COLLECTION_MODEL'
export const COLLECTIONS_ADD_MODEL_TO_COLLECTION = 'COLLECTIONS_ADD_MODEL_TO_COLLECTION'
export const COLLECTIONS_SET_ALL_INFO = 'COLLECTIONS_SET_ALL_INFO'
export const COLLECTIONS_FETCH_A_COLLECTIONS_BUNDLES = 'COLLECTIONS_FETCH_A_COLLECTIONS_BUNDLES';
export const COLLECTION_ADD_TO_BUNDLE_LIST = 'COLLECTION_ADD_TO_BUNDLE_LIST';
export const COLLECTION_REMOVE_FROM_BUNDLE_LIST = 'COLLECTION_REMOVE_FROM_BUNDLE_LIST';
export const COLLECTIONS_PUT_COLLECTION = 'COLLECTIONS_PUT_COLLECTION';
export const COLLECTIONS_ADD_COLLECTION = 'COLLECTIONS_ADD_COLLECTION';

//Bundles
export const BUNDLES_GET_ALL = "BUNDLES_GET_ALL";
export const BUNDLES_SET_LIST = "BUNDLES_SET_LIST";
export const BUNDLES_CLEAR_LIST = "BUNDLES_CLEAR_LIST";
export const ADD_COLLECTION_TO_BUNDLE = "ADD_COLLECTION_TO_BUNDLE";
export const REMOVE_COLLECTION_TO_BUNDLE = "REMOVE_COLLECTION_TO_BUNDLE";

//Users
export const SET_USER_DATA = "SET_USER_DATA";
export const EDIT_USER_DATA = "EDIT_USER_DATA"; 
export const LOGOUT_USER = "LOGOUT_USER"; 
export const REFRESH_USER = "REFRESH_USER"; 
export const CHECK_FOR_USER = "CHECK_FOR_USER"; 
export const USER_IS_LOGGING_IN = "USER_IS_LOGGING_IN";
export const USER_IS_NOT_LOGGED_IN = "USER_IS_NOT_LOGGED_IN";


//Models
export const MODELS_GET_POST_URL = "MODELS_GET_POST_URL";
export const MODELS_POST_MODEL_TO_S3 = "MODELS_POST_MODEL_TO_S3";


//UNIVERSAL
export const UNIVERSAL_UPLOAD_FILE = "UNIVERSAL_UPLOAD_FILE";

//UNIVERSAL
export const APP_CREATE = "APP_CREATE";
export const APP_GETALL = "APP_GETALL";
export const APP_ADD_ALL_TO_LIST = "APP_ADD_ALL_TO_LIST";
export const UPDATE_APP_IN_LIST = "UPDATE_APP_IN_LIST";
