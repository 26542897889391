import Actions from '../actions'
import { call, put } from "redux-saga/effects";

export const getBundleList = requestModule => 
function* getBundleListSaga(action) {
  try {
    const req = yield call(requestModule, {
      path: `bundle`
    });      

    let bundles = [];
    req.data.bundles.forEach(bundle => {
      bundles.push({
        description: bundle.description,
        enabled: bundle.enabled,
        id: bundle.PK
      })
    });

    yield put(Actions.Bundles.setBundleList(bundles));

    if (action.callback) {        
      yield call(action.callback, { success: true });        
    }
  } 
  catch (err) {    
    if (action.callback) {
      yield call(action.callback, { success: false, msg: err.message });
    }
    else{      
      throw err;
    }
  }
};

export const addCollectionToBundle = requestModule => 
function* addCollectionToBundleSaga(action) {

  try {

    let newBundle = {}
    for (let index = 0; index < action.bundles.length; index++) {
      const bundle_id = action.bundles[index];
      
      const req = yield call(requestModule, {
        path: `bundle/${bundle_id}/collection/${action.collection_id}`,
        method: "POST"
      });

      newBundle = req.data

      yield put(Actions.Collections.addToBundleList(newBundle));
    }

    if (action.callback) {        
      yield call(action.callback, { success: true });        
    }
  } 
  catch (err) {    
    if (action.callback) {
      yield call(action.callback, { success: false, msg: err.message });
    }
    else{      
      throw err;
    }
  }
};

export const removeCollectionToBundle = requestModule => 
function* removeCollectionToBundleSaga(action) {
  try {

    for (let index = 0; index < action.bundles.length; index++) {
      const bundle_id = action.bundles[index];
      
      yield call(requestModule, {
        path: `bundle/${bundle_id}/collection/${action.collection_id}`,
        method: "DELETE"
      });

      yield put(Actions.Collections.removeFromBundleList({bundle_id, collection_id: action.collection_id}));
    }


    if (action.callback) {        
      yield call(action.callback, { success: true });        
    }
  } 
  catch (err) {    
    if (action.callback) {
      yield call(action.callback, { success: false, msg: err.message });
    }
    else{      
      throw err;
    }
  }
};