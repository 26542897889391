import * as TYPES from "./types";

const getModelPostUrl = (filename, modelData, callback) => {
    return{
        type: TYPES.MODELS_GET_POST_URL,
        filename,
        modelData,
        callback
    };
}

const postModelToS3 = (url, modelData, callback) => {
  return{
      type: TYPES.MODELS_POST_MODEL_TO_S3,
      url,
      modelData,
      callback
  };
}

export default {
  getModelPostUrl,
  postModelToS3
}