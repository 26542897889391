import React from "react";
import { Form, Message } from "semantic-ui-react";
import { Auth } from "aws-amplify";
import UserController from "../../utils/UserController";
import { connect } from "react-redux";
import Actions from "../../actions";

class Login extends React.Component {
  state = {
    password: "",
    email: "",
    error: false,
    errorMsg: ""
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { password, email } = this.state;

    Auth.signIn({
      username: email, // Required, the username
      password // Optional, the password
    })
      .then(user => {
        const userData = UserController.use.GetUserDataFromAPIUser(user);

        if(userData.groups && userData.groups.includes("Admin"))
        {
          this.props.setUserData(userData);
        } else
        {
          this.setState({ error: true, errorMsg: "You do not have permission." });
          UserController.use.SignOut();
        }
      })
      .catch(err => {
        this.setState({ error: true, errorMsg: err.message });
      });
  };

  render() {
    const { password, email } = this.state;

    let errorBanner = <></>;
    if (this.state.error) {
      errorBanner = (
        <Message negative>
          <Message.Header>Login Failed</Message.Header>
          <p>{this.state.errorMsg}</p>
        </Message>
      );
    }

    return (
      <div>
        {errorBanner}
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <Form.Input
              placeholder="Email"
              name="email"
              value={email}
              onChange={this.handleChange}
              required
              label="Email"
            />
            <Form.Input
              placeholder="Password"
              name="password"
              value={password}
              onChange={this.handleChange}
              type="password"
              required
              label="Password"
            />
            <Form.Button content="Login" />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default connect(null, {
  setUserData: (userData)=>Actions.User.setUserData(userData)
})(Login);
