import React from "react";
import {
  Header,
  Segment,
  Dropdown,
  Button,
  Grid,
  Form,
  Label,
  Checkbox,
  Menu,
  Input,
  Modal,
  Message,
  List
} from "semantic-ui-react";
import { connect } from "react-redux";
import Actions from "../../actions";
import UTILITIES from "../../utils/Utilities";
import EditObjectsLabelList from "../EditObjectsLabels/EditObjectsLabel_List";
import StatusDropdown from "../Status/StatusDropdown";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";

class EditData extends React.Component {
  constructor(props) {
    super(props);

    let intialData =
      this.props.collection.data.length === 0
        ? null
        : this.props.collection.data[0];

    let initialGroup = "";
    if (intialData) {
      const groupNames = intialData.groups
        ? Object.keys(intialData.groups)
        : [];
      initialGroup = groupNames.length > 0 ? groupNames[0] : "";
    }

    this.state = {
      somethingHasChanged: false,
      activePanel: "labels",
      data_id: intialData ? intialData.id : -1,
      status: intialData ? intialData.status : "draft",
      description: intialData ? intialData.description : "",
      primaryLanguage: intialData ? intialData.primaryLanguage : "en",
      default: intialData ? intialData.default : false,
      labels: intialData ? intialData.labels : {},
      groups: intialData ? intialData.groups : {},
      showObject: null,
      selectedGroup: initialGroup,
      modal: null,
      isSaving: false,
      error: false,
      errorMessage: "",
      showSaveSuccess: false,
      loadingData: false
    };
  }

  onClick = (e, { name }) => {
    switch (name) {
      case "change_data":
        console.log("Handle Change Data");
        break;

      case "new_data":
        console.log("Handle New Data");
        break;

      case "default_data_check":
        this.setState({
          default: !this.state.default
        });
        break;

      case "save_changes":
        this.doSave();
        break;

      case "discard_changes":
        this.setState({ modal: null });
        this.setStateToCollectionData();
        break;

      case "modal_cancel":
        this.setState({ modal: null });
        break;

      case "initialize_labels":
        this.doInitializeLabels();
        break;

      case "modal_create_group":
        const groups = this.state.groups ? this.state.groups : {};
        groups[this.state.modal.groupName] = [];
        this.setState({
          groups,
          selectedGroup: this.state.modal.groupName,
          modal: null
        });
        break;

      case "modal_edit_group":
        if (groups.hasOwnProperty(this.state.modal.originalGroupName)) {
          let groups = { ...this.state.groups };
          groups[this.state.modal.groupName] = [
            ...groups[this.state.modal.originalGroupName]
          ];
          delete groups[this.state.modal.originalGroupName];
          this.setState({
            groups,
            selectedGroup: this.state.modal.groupName
          });
        }

        break;

      default:
        console.log("Unhandled click", name);
        break;
    }
  };

  handleDeleteGroup(groupName) {
    let groups = this.state.groups ? { ...this.state.groups } : {};
    if (groups.hasOwnProperty(groupName)) {
      delete groups[groupName];
      this.setState({
        groups,
        modal: null,
        selectedGroup: ""
      });
    }
  }

  handleRenameGroup(originalGroupName, newGroupName) {
    let groups = this.state.groups ? { ...this.state.groups } : {};
    if (groups.hasOwnProperty(originalGroupName)) {
      const groupObjs = groups[originalGroupName];
      groups[newGroupName] = [...groupObjs];
      delete groups[originalGroupName];
      this.setState({
        groups,
        modal: null,
        selectedGroup: newGroupName
      });
    }
  }

  //Handles when we need to initialize the labels
  doInitializeLabels() {
    const labels = {};
    this.props.collection.objectList.forEach(objName => {
      const label = UTILITIES.ObjectNameToLabel(objName);
      labels[objName] = label;
    });

    this.setState({ labels });
  }

  //Handles a change in the dropdowns
  onDropdownChange = (e, { value, name }) => {
    switch (name) {
      case "group":
        this.setState({
          selectedGroup: value
        });
        break;
      default:
        break;
    }
  };

  //Handles all the input boxes
  onInputChange = (e, d) => {
    if (d.name === "data_description") {
      this.setState({ description: d.value });
    } else if (d.name === "modal_group_name") {
      let modal = { ...this.state.modal, groupName: d.value };
      this.setState({ modal });
    }
  };

  //Handles changing a lable for a specific object
  changeObjectLabel = (objectName, newLabel) => {
    let modifiedLabel = { ...this.state.labels };
    modifiedLabel[objectName] = newLabel;
    this.setState({ labels: modifiedLabel });
  };

  //Resets the data to the collection data
  setStateToCollectionData() {
    let origData = null;
    this.props.collection.data.forEach(data => {
      if (data.id === this.state.data_id) {
        origData = data;
      }
    });

    if (!origData) {
      return;
    }

    this.setState({
      status: origData.status,
      description: origData.description,
      primaryLanguage: origData.primaryLanguage,
      default: origData.default,
      labels: origData.labels,
      groups: origData.groups
    });
  }

  //Handles when the user clicks save
  doSave() {
    if (this.state.data_id === -1) {
      //New data
    } else {
      let origData = null;
      this.props.collection.data.forEach(data => {
        if (data.id === this.state.data_id) {
          origData = data;
        }
      });

      if (!origData) {
        return;
      }

      let changedData = {};
      const {
        status,
        description,
        primaryLanguage,
        labels,
        groups
      } = this.state;

      if (status !== origData.status) {
        changedData.status = status;
      }

      if (description !== origData.description) {
        changedData.description = description;
      }

      if (primaryLanguage !== origData.primaryLanguage) {
        changedData.primaryLanguage = primaryLanguage;
      }

      if (this.state.default !== origData.default) {
        changedData.default = this.state.default;
      }

      let namesMatch = true;
      const goNames = labels ? Object.keys(labels) : [];
      goNames.forEach(goName => {
        if (origData.labels) {
          if (labels[goName] !== origData.labels[goName]) {
            namesMatch = false;
          }
        } else {
          namesMatch = false;
        }
      });

      if (!namesMatch) {
        changedData.labels = labels;
      }

      const groupsMatch = this.areGroupsTheSame(
        groups ? groups : {},
        origData.groups ? origData.groups : {}
      );
      if (!groupsMatch) {
        changedData.groups = groups;
      }

      this.setState({ isSaving: true, error: false, errorMessage: "" });
      this.props.patchData(
        this.props.collection.id,
        this.state.data_id,
        changedData,
        resp => {
          if (resp.success) {
            this.setState({ isSaving: false, showSaveSuccess: true });
            setTimeout(() => this.setState({ showSaveSuccess: false }), 3000);
          } else {
            this.setState({
              isSaving: false,
              error: true,
              errorMessage: resp.msg
            });
          }
        }
      );
    }
  }

  isObjectInGroup(objName, groupName) {
    if (this.state.groups.hasOwnProperty(groupName)) {
      return this.state.groups[groupName].includes(objName);
    } else {
      return false;
    }
  }

  toggleObjectGroupMembership(objName, groupName) {
    if (this.state.groups.hasOwnProperty(groupName)) {
      let groupArray = [...this.state.groups[groupName]];
      if (groupArray.includes(objName)) {
        //remove it
        groupArray = this.state.groups[groupName].filter(
          name => name !== objName
        );
      } else {
        //add it
        groupArray.push(objName);
      }

      let newGroupState = { ...this.state.groups };
      newGroupState[groupName] = groupArray;

      console.log(newGroupState);

      this.setState({ groups: newGroupState });
    }
  }

  componentDidUpdate() {
    if (this.data_id !== -1) {
      let somethingHasChanged = this.hasSomethingChanged();

      if (somethingHasChanged !== this.state.somethingHasChanged) {
        this.setState({ somethingHasChanged });
        this.props.setConfirmTabChange(somethingHasChanged);
      }
    }
  }

  hasSomethingChanged() {
    const {
      data_id,
      status,
      description,
      primaryLanguage,
      labels,
      groups
    } = this.state;

    let origData = null;
    this.props.collection.data.forEach(data => {
      if (data.id === data_id) {
        origData = data;
      }
    });

    if (!origData) {
      return false;
    }

    if (status !== origData.status) {
      return true;
    }

    if (description !== origData.description) {
      return true;
    }

    if (primaryLanguage !== origData.primaryLanguage) {
      return true;
    }

    if (this.state.default !== origData.default) {
      return true;
    }

    if (labels === null && origData.labels) {
      return true;
    }

    let namesMatch = true;
    const goNames = labels ? Object.keys(labels) : [];
    goNames.forEach(goName => {
      if (origData.labels) {
        if (labels[goName] !== origData.labels[goName]) {
          namesMatch = false;
        }
      } else {
        namesMatch = false;
      }
    });

    if (!namesMatch) {
      return true;
    }

    const groupsMatch = this.areGroupsTheSame(
      groups ? groups : {},
      origData.groups ? origData.groups : {}
    );

    if (!groupsMatch) {
      return true;
    }

    return false;
  }

  areGroupsTheSame(currentGroups, originalGroups) {
    const currentGroupNames = Object.keys(currentGroups);
    const origGroupNames = Object.keys(originalGroups);

    //Start by simply checking the group name counts
    if (currentGroupNames.length !== origGroupNames.length) {
      return false; //Outta here
    }

    //Now check to see if the group names match
    let groupNamesMatch = true;
    currentGroupNames.forEach(cGroupName => {
      if (groupNamesMatch) {
        //If this gets set to false something is already different and we don't need to waste time checking anymore
        if (!origGroupNames.includes(cGroupName)) {
          groupNamesMatch = false;
        }
      }
    });

    //Outta here
    if (!groupNamesMatch) {
      return false;
    }

    //Finally we need to check the object list in each group
    let groupsMatch = true;
    currentGroupNames.forEach(groupName => {
      if (groupsMatch) {
        //If this gets set to false something is already different and we don't need to waste time checking anymore
        const current_ObjectList = currentGroups[groupName];
        const original_ObjectList = originalGroups[groupName];

        if (current_ObjectList.length === original_ObjectList.length) {
          current_ObjectList.forEach(objName => {
            if (!original_ObjectList.includes(objName)) {
              groupsMatch = false;
            }
          });
        } else {
          //Count is off
          groupsMatch = false;
        }
      }
    });

    return groupsMatch;
  }

  renderSelectAdd = () => {
    const dataCount = this.props.collection.data.length;

    return (
      <Segment>
        <Button
          content="Change selected data"
          icon="file code"
          onClick={this.onClick}
          name="change_data"
          disabled={dataCount > 1 ? false : true}
        />
        <Button
          content="Create New Data"
          icon="add"
          floated="right"
          onClick={this.onClick}
          name="new_data"
          disabled
        />
      </Segment>
    );
  };

  renderSaveDiscard = () => {
    let discardIsDisabled = !this.state.somethingHasChanged;
    let saveIsDisabled = !this.state.somethingHasChanged;

    if (this.state.data_id === -1) {
      discardIsDisabled = true;
    }

    if (!this.state.description) {
      saveIsDisabled = true;
    }

    return (
      <div>
        <Button
          negative
          disabled={discardIsDisabled}
          name="discard_changes"
          onClick={() =>
            this.setState({
              modal: { mode: "confirm_discard" }
            })
          }
        >
          Discard Changes
        </Button>
        <Button
          positive
          disabled={saveIsDisabled}
          name="save_changes"
          onClick={this.onClick}
          loading={this.state.isSaving}
        >
          Save Changes
        </Button>
        {this.state.showSaveSuccess ? (
          <Label basic color="green" pointing="left">
            Saved!
          </Label>
        ) : null}
      </div>
    );
  };

  renderDataDetails = () => {
    return (
      <Form>
        <Form.TextArea
          label="Description"
          placeholder="Describe this data..."
          value={this.state.description}
          onChange={this.onInputChange}
          name="data_description"
        />
        <Form.Group inline>
          <StatusDropdown
            status={this.state.status}
            onDropdownChange={(e, d) => {
              this.setState({ status: d.value });
            }}
          />
          <LanguageDropdown
            language={
              this.state.primaryLanguage ? this.state.primaryLanguage : ""
            }
            onChange={(e, d) => {
              this.setState({
                primaryLanguage: d.value
              });
            }}
          />
          <Checkbox
            label="Default"
            checked={this.state.default}
            name="default_data_check"
            onClick={this.onClick}
          />
        </Form.Group>
      </Form>
    );
  };

  renderTabbedView() {
    return (
      <Grid.Column>
        <Menu attached="top" tabular>
          <Menu.Item
            name="Labels"
            active={this.state.activePanel === "labels"}
            onClick={() =>
              this.setState({
                activePanel: "labels"
              })
            }
          ></Menu.Item>
          <Menu.Item
            name="Groups"
            active={this.state.activePanel === "groups"}
            onClick={() =>
              this.setState({
                activePanel: "groups"
              })
            }
          />
        </Menu>
        <Segment attached="bottom">
          {this.state.activePanel === "labels" ? this.renderLabelPanel() : null}
          {this.state.activePanel === "groups" ? this.renderGroupPanel() : null}
        </Segment>
      </Grid.Column>
    );
  }

  renderLabelPanel = () => {
    if (this.state.loadingData) {
      return (
        <>
          <Header>Loading Labels</Header>
          <Button
            name="initialize_labels"
            onClick={this.onClick}
            disabled={this.props.collection.objectName}
          >
            Initialize Labels
          </Button>
        </>
      );
    } else if (!this.state.labels) {
      return (
        <>
          <Header>This data has no labels</Header>
          <Button
            name="initialize_labels"
            onClick={this.onClick}
            disabled={this.props.collection.objectName}
          >
            Initialize Labels
          </Button>
        </>
      );
    } else {
      return (
        <EditObjectsLabelList
          labels={this.state.labels}
          showingObject={this.state.showObject}
          onLabelChange={(goName, label) => {
            this.changeObjectLabel(goName, label);
          }}
          toggleShowObject={goName => {
            if (this.state.showObject === goName) {
              this.setState({ showObject: "" });
            } else {
              this.setState({ showObject: goName });
            }
          }}
        />
      );
    }
  };

  renderGroupPanel = () => {
    const groupNames = this.state.groups ? Object.keys(this.state.groups) : [];
    const groupDropdownOptions = groupNames.map(name => ({
      key: name,
      text: name,
      value: name
    }));
    return (
      <div>
        <Dropdown
          name="group"
          placeholder="Group"
          selection
          options={groupDropdownOptions}
          onChange={this.onDropdownChange}
          value={this.state.selectedGroup}
          disabled={groupNames.length === 0}
        />
        <Button
          icon="edit"
          disabled={!this.state.selectedGroup}
          onClick={() =>
            this.setState({
              modal: {
                mode: "edit_group",
                groupName: this.state.selectedGroup,
                originalGroupName: this.state.selectedGroup,
                confirmDelete: false
              }
            })
          }
        />
        <Button
          floated="right"
          icon="plus"
          content="Create Group"
          onClick={() =>
            this.setState({ modal: { mode: "create_group", groupName: "" } })
          }
        />
        <List>
          {this.state.selectedGroup ? (
            this.props.collection.objectList.map(objName => {
              return (
                <List.Item key={objName}>
                  <Checkbox
                    label={objName}
                    onClick={() =>
                      this.toggleObjectGroupMembership(
                        objName,
                        this.state.selectedGroup
                      )
                    }
                    checked={this.isObjectInGroup(
                      objName,
                      this.state.selectedGroup
                    )}
                  />
                </List.Item>
              );
            })
          ) : (
            <>
              <List.Item>Select or create a group</List.Item>
            </>
          )}
        </List>
      </div>
    );
  };

  renderModal = () => {
    if (this.state.modal === null) {
      return null;
    }

    const { mode } = this.state.modal;

    if (mode === "confirm_discard") {
      return this.renderModal_ConfirmDiscard();
    } else if (mode === "create_group") {
      return this.renderModal_NewGroup();
    } else if (mode === "edit_group") {
      return this.renderModal_EditGroup();
    }
  };

  renderModal_NewGroup = () => {
    //Editing an existing title
    const { groupName } = this.state.modal;
    let saveDisabled = groupName ? false : true;

    const groupNames = this.state.groups ? Object.keys(this.state.groups) : [];

    let showUsedNameWarning = false;
    if (groupNames.includes(groupName)) {
      saveDisabled = true;
      showUsedNameWarning = true;
    }

    return (
      <Modal open size="tiny">
        <Modal.Header>New Group Name</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Input
                label="Group Name"
                value={groupName}
                name="modal_group_name"
                onChange={this.onInputChange}
              />
              {showUsedNameWarning ? (
                <Label pointing prompt>
                  {`Group name is already used`}
                </Label>
              ) : null}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="modal_create_group"
            positive
            icon="plus"
            labelPosition="right"
            content="Create"
            onClick={this.onClick}
            disabled={saveDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderModal_EditGroup = () => {
    //Editing an existing group
    const { groupName, originalGroupName } = this.state.modal;
    let saveDisabled = false;
    let showUsedNameWarning = false;

    if (!groupName || groupName === originalGroupName) {
      saveDisabled = true;
    } else {
      const groupNames = this.state.groups
        ? Object.keys(this.state.groups)
        : [];

      if (groupNames.includes(groupName)) {
        saveDisabled = true;
        showUsedNameWarning = true;
      }
    }

    return (
      <Modal open size="tiny">
        <Modal.Header>Edit</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Input
                label="Group Name"
                value={groupName}
                name="modal_group_name"
                onChange={this.onInputChange}
              />
              {showUsedNameWarning ? (
                <Label pointing prompt>
                  {`Group name is already used`}
                </Label>
              ) : null}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            positive
            icon="check"
            labelPosition="right"
            content="Save"
            onClick={() => this.handleRenameGroup(originalGroupName, groupName)}
            disabled={saveDisabled}
          />
          <Button
            negative
            icon="trash"
            labelPosition="right"
            content="Delete Group"
            onClick={() => this.handleDeleteGroup(originalGroupName)}
            floated="left"
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderModal_ConfirmDiscard = () => {
    return (
      <Modal open size="tiny">
        <Modal.Header>Discard Changes?</Modal.Header>
        <Modal.Content>
          Are you sure you want to discard all changes?
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="discard_changes"
            negative
            icon="trash"
            labelPosition="right"
            content="Yes, Discard Changes"
            onClick={this.onClick}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  render_Error() {
    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>Uh Oh!</Message.Header>
          <p>{this.state.errorMessage}</p>
        </Message>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <>
        {this.renderSelectAdd()}
        <Segment>
          {this.render_Error()}
          {this.renderSaveDiscard()}
          {this.renderDataDetails()}
          {this.renderTabbedView()}
        </Segment>
        {this.renderModal()}
      </>
    );
  }
}

export default connect(null, {
  patchData: (collection_id, data_id, data, callback) => {
    return Actions.Collections.patchCollectionData(
      collection_id,
      data_id,
      data,
      callback
    );
  },
  getCollectionData: (collection_id, data_id, callback) => {
    return Actions.Collections.fetchACollectionsData(
      collection_id,
      data_id,
      callback
    );
  }
})(EditData);
