import React from 'react'
import { Header, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginState from '../components/LoginState/LoginState'

class Home extends React.Component {

    render() {

        if(this.props.loginStatus === "isLoggedIn")
        {
            return (
                <div>
                    <Header>What would you like to do?</Header>
                    <Button as={Link} to="/new">Create a new Collection</Button>
                    <Button as={Link} to="/collections">Edit existing Collection</Button>
                    <Button as={Link} to="/new/app">Create a new App</Button>

                </div>
            )
        } else
        {
            return <LoginState loginStatus={this.props.loginStatus}/> 
        }
        
    } 
} 

const MapStateToProps = state => {
    const loginStatus = state.User.loginStatus;

  return {
    loginStatus
  };
}

export default connect(MapStateToProps)(Home);