import React from "react";
import { Input, Image, Button, Header, Label } from "semantic-ui-react";
import "./NewApp.css";
import { connect } from "react-redux";
import Actions from "../../actions";
import ListAllApps from "../../components/ListAllApps/ListAllApps";

import * as uuid from "uuid"

class NewApp extends React.Component {
  state = {
    error: false,
    errorMessage: false,
    showSpinner: false,
    image: "",
    title: "",
    description: "",
    app_id: "",
    displayApp: false
  };

  setError = message => {
    this.setState({ error: true, errorMessage: message });
  };

  changeMeta(key, value) {
    let newMeta = {}
    newMeta[key] = value
    this.setState(newMeta)
  }

  changeImage(image_file) {
    this.ImageToUrl(image_file).then(image => {
      this.setState({image})
    })
  }
  
  ImageToUrl(file) {
    return new Promise((win, fail) => {
      let reader = new FileReader()
  
      reader.onload = function(e) {
        if(e.target.result) {
          win(e.target.result)
        } else {
          fail("")
        }
      };
  
      reader.readAsDataURL(file)
    })
  }
  
  render_getInfo() {
    return (
      <div id="get_app_info">
        <Header as='h2'>Create New App</Header>
        <div id="get_app_info_meta">
          <Input required placeholder='Title' value={this.state.title} onChange={(e)=> this.changeMeta("title", e.target.value)}/>
          <Input placeholder='Description' value={this.state.description} onChange={(e)=> this.changeMeta("description", e.target.value)}/>
        </div>
        <div id="get_app_info_image">
          <Image src={this.state.image} size='medium' />
          <Input type="file" placeholder='Image' onChange={(e) => this.changeImage(e.target.files[0])}/>
        </div>
        <Button onClick={()=> this.onSubmit()} loading={this.state.showSpinner}>Submit</Button>
      </div>
    )
  }

  render_displayInfo() {
    return (
      <div id="display_app_info">
        <Header as='h2'>The New App</Header>
        <Label size='big'>
          Id - {this.state.app_id}
        </Label>
        <Image src={this.state.image} size='medium' />
        <Label size='big'>
          Title - {this.state.title}
        </Label>
        <Label size='big'>
          Description - {this.state.description}
        </Label>
      </div>
    )
  }

  onSubmit() {
    this.setState({showSpinner: true})
    this.props.uploadFile(this.state.image, "Thumbnails", (newFileName) => {

      let appData = {
        title: this.state.title,
        description: this.state.description,
      }

      if(newFileName) {
        appData.image = newFileName
      }

      this.props.createApp(appData, (result) => {
        if(result.success) {
          this.setState({app_id: result.data.PK, displayApp: true})
        } else {
          this.setState({
            error: true,
            errorMessage: result.msg
          });
        }
      })
    })
  }

  render() {
    return (
      <>
      {this.state.displayApp ? this.render_displayInfo() : this.render_getInfo()}
      <ListAllApps/>
      </>
    );
  }
}

export default connect(null, {
  createApp: (appData, callback) => Actions.Apps.createApp(appData, callback),
  uploadFile: (file, folder, cb) => {
    if(file) {
      const filename = `${uuid.v4()}.thumbnail`
      return Actions.Universal.uploadFile({ file, folder, filename }, cb)
    } else {
      cb("")
      return {type:"NO ACTION"}
    }
  },
})(NewApp);

