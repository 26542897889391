import * as TYPES from "./types";

const fetchCollectionBundle = (bundleId, offset, limit, callback) => {
  if (!bundleId) {
    throw new Error("bundleId cannot be null or empty");
  }

  if (!offset && offset !== 0) {
    throw new Error("offset cannot be null or empty");
  }

  if (!limit && limit !== 0) {
    throw new Error("limit cannot be null or empty");
  }

  return {
    type: TYPES.COLLECTIONS_BUNDLE_FETCH,
    payload: {
      bundleId: bundleId,
      offset,
      limit
    },
    callback
  };
};

const getCollectionModelURL = (collectionId, modelId, filename, callback) => {
  return {
    type: TYPES.COLLECTIONS_GET_MODEL_URL,
    collectionId,
    modelId,
    filename,
    callback
  };
};

const setCollectionModelURL = (collectionId, modelName, URL) => {
  return {
    type: TYPES.COLLECTIONS_SET_MODEL_URL,
    collectionId,
    modelName,
    URL
  };
};

const fetchAllCollections = callback => {
  return {
    type: TYPES.COLLECTIONS_FETCH_ALL,
    callback
  };
};

const fetchACollection = (id, callback) => {
  return {
    type: TYPES.COLLECTIONS_FETCH_A_COLLECTION,
    id,
    callback
  };
};

const fetchACollectionsData = (collection_id, data_id, callback) => {
  return {
    type: TYPES.COLLECTIONS_FETCH_A_COLLECTION_DATA,
    data_id,
    collection_id,
    callback
  };
};

const setACollectionsLabelsAndGroups = (collection_id, data_id, objectData) => {
  return {
    type: TYPES.COLLECTIONS_SET_A_COLLECTIONS_LABELS_GROUPS,
    collection_id,
    data_id,
    objectData
  };
};

const setCollectionList = collections => {
  return {
    type: TYPES.COLLECTIONS_SET_COLLECTION_LIST,
    collections
  };
};

const setAllInfoForCollection = (collection, callback) => {
  return {
    type: TYPES.COLLECTIONS_SET_ALL_INFO,
    collection,
    callback
  };
};

const patchCollectionMeta = (id, meta, callback) => {
  return {
    type: TYPES.COLLECTIONS_PATCH_COLLECTION_META,
    payload: {
      id,
      meta
    },
    callback
  };
};

const setCollectionMeta = (id, meta) => {
  return {
    type: TYPES.COLLECTIONS_SET_COLLECTION_META,
    payload: {
      id,
      meta
    }
  };
};

const patchCollectionData = (collection_id, data_id, data, callback) => {
  return {
    type: TYPES.COLLECTIONS_PATCH_COLLECTION_DATA,
    payload: {
      collection_id,
      data_id,
      data
    },
    callback
  };
};

const setCollectionData = (collection_id, data_id, data) => {
  return {
    type: TYPES.COLLECTIONS_SET_COLLECTION_DATA,
    payload: {
      collection_id,
      data_id,
      data
    }
  };
};

const setCollectionsRetrieved = (
  total,
  offset,
  allHaveBeenRetrieved,
  callback
) => {
  if (!total && total !== 0) {
    throw new Error("total cannot be null or empty");
  }

  if (!offset && offset !== 0) {
    throw new Error("offset cannot be null or empty");
  }

  if (allHaveBeenRetrieved === null || allHaveBeenRetrieved === undefined) {
    throw new Error("allHaveBeenRetrieved cannot be null or undefined");
  }

  return {
    type: TYPES.COLLECTIONS_SET_RETRIEVED,
    payload: {
      total,
      offset,
      allHaveBeenRetrieved
    },
    callback
  };
};

const setCollectionObjectList = (collection_id, object_list) => {
  return {
    type: TYPES.COLLECTIONS_SET_OBJECT_LIST,
    collection_id,
    object_list
  };
};

const updateCollectionModel = (collection_id, model_id, model, callback) => {
  return {
    type: TYPES.COLLECTIONS_UPDATE_COLLECTION_MODEL,
    collection_id,
    model_id,
    model,
    callback
  };
}

const putCollectionModel = (collection_id, lod, format, filename, status, version, callback) => {
  return {
    type: TYPES.COLLECTIONS_PUT_COLLECTION_MODEL,
    collection_id,
    lod, format, filename, status, version,
    callback
  };
}

const addModelToCollection = (collection_id, model_id, model) =>{

  return {
    type: TYPES.COLLECTIONS_ADD_MODEL_TO_COLLECTION,
    collection_id,
    model_id, 
    model
  };
}

const addToBundleList = (bundle) => {
  return {
      type: TYPES.COLLECTION_ADD_TO_BUNDLE_LIST,
      bundle
  }
}

const removeFromBundleList = (bundle) => {
  return {
      type: TYPES.COLLECTION_REMOVE_FROM_BUNDLE_LIST,
      bundle
  }
}

const putCollection = (collection, callback) => {
  return {
    type: TYPES.COLLECTIONS_PUT_COLLECTION,
    collection,
    callback
  }
}

const addCollection = (collection) => {
  return {
    type: TYPES.COLLECTIONS_ADD_COLLECTION,
    collection
  }
}

export default {
  setCollectionList,
  setCollectionsRetrieved,
  fetchCollectionBundle,
  fetchAllCollections,
  fetchACollection,
  patchCollectionMeta,
  setCollectionMeta,
  getCollectionModelURL,
  setCollectionModelURL,
  patchCollectionData,
  setCollectionData,
  setCollectionObjectList,
  fetchACollectionsData,
  setACollectionsLabelsAndGroups,
  updateCollectionModel,
  putCollectionModel,
  addModelToCollection,
  setAllInfoForCollection,
  addToBundleList,
  putCollection,
  addCollection,
  removeFromBundleList
};
