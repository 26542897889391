import React, { createRef } from "react";
import {
  Button,
  Icon,
  Menu,
  Grid,
  Ref,
  Sticky,
  Header,
  Message,
  TextArea,
  Segment,
  Form
} from "semantic-ui-react";
import PlayerCanvas from "../../components/PlayerCanvas/PlayerCanvas";
import WebGLPlayer from "../../player/WebGLPlayer";
import EditObjectsLabelList from "../../components/EditObjectsLabels/EditObjectsLabel_List";
import UTILITIES from "../../utils/Utilities";
import { v4 as uuidv4 } from 'uuid';

const playerCanvasID = "webGL_Canvas";

class Data extends React.Component {
  player = null;
  contextRef = createRef();

  state = {
    isLoading: true,
    labels: {},
    showObject: null,
    description: "Default English data"
  };

  //Handles changing a lable for a specific object
  changeObjectLabel = (objectName, newLabel) => {
    let modifiedLabel = { ...this.state.labels };
    modifiedLabel[objectName] = newLabel;
    this.setState({ labels: modifiedLabel });
  };

  handleClickNext = () => {
    this.props.data.id = uuidv4();
    this.props.data.description = this.state.description;
    this.props.data.labels = this.state.labels

    this.props.onNext();
  };

  componentDidMount() {
    this.makePlayer();
  }

  componentWillUnmount() {
    this.player.dispose();
  }

 makePlayer() {
    console.log("DATA make player")

    let playerCanvas = document.getElementById(playerCanvasID);
    if (playerCanvas) {
      this.player = new WebGLPlayer(playerCanvas);
      this.player.loadGLBFromURL(
        this.props.modelURL,
        () => {
          //Success Data
          console.log("HIT Data success")
          const labels = {};
          this.player.collectionObjects.objectLookup.forEach((t, objName) => {
            const label = UTILITIES.ObjectNameToLabel(objName);
            labels[objName] = label;
          });
          //Success
          this.setState({
            labels,
            isLoading: false
          });
        },
        errorMsg => {
          //Fail
          this.props.errorMessage(errorMsg);
        }
      );
    }
  }

  renderData() {
    if (this.state.isLoading) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Loading Data...</Message.Header>
          </Message.Content>
        </Message>
      );
    } else {
      return (
        <Segment>
          <Header>Default English Data</Header>
          <Form>
            <label>Data description</label>
            <TextArea
              placeholder="Describe this data..."
              value={this.state.description}
              onChange={(e, d) => {
                this.setState({ description: d.value });
              }}
            />
          </Form>

          <EditObjectsLabelList
            labels={this.state.labels}
            showingObject={this.state.showObject}
            onLabelChange={(goName, label) => {
              this.changeObjectLabel(goName, label);
            }}
            toggleShowObject={goName => {
              if (this.state.showObject === goName) {
                this.setState({ showObject: "" });
              } else {
                this.setState({ showObject: goName });
              }
            }}
          />
        </Segment>
      );
    }
  }

  render() {
    return (
      <>
        <Menu secondary>
          <Menu.Item header>Setup Data</Menu.Item>
          <Menu.Menu position="right">
            <Button
              icon
              floated="right"
              labelPosition="right"
              onClick={this.handleClickNext}
            >
              Next
              <Icon name="right arrow" />
            </Button>
          </Menu.Menu>
        </Menu>
        <Grid relaxed columns={2}>
          <Grid.Column>
            <Grid.Row>
              <Sticky context={this.contextRef}>
                <div style={{ height: "500px", width: "500px" }}>
                  <PlayerCanvas canvasId={playerCanvasID} />
                </div>
              </Sticky>
            </Grid.Row>
          </Grid.Column>
          <Ref innerRef={this.contextRef}>
            <Grid.Column>{this.renderData()}</Grid.Column>
          </Ref>
        </Grid>
      </>
    );
  }
}

export default Data;
