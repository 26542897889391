import React from "react";
import { Dropdown } from "semantic-ui-react";
import StatusLabel from "./StatusLabel";

const StatusDropdown = ({status, onDropdownChange}) => {

  const statuses = [
    {
      key: "draft",
      text: "Draft",
      value: "draft",
      content: <StatusLabel status="draft" />
    },
    {
      key: "review",
      text: "Review",
      value: "review",
      content: <StatusLabel status="review" />
    },
    {
      key: "published",
      text: "Published",
      value: "published",
      content: <StatusLabel status="published" />
    }
  ];

  const trigger = (
    <span>
      <StatusLabel status={status} />
    </span>
  );

  return (
    <Dropdown
      placeholder="Status"
      options={statuses}
      onChange={onDropdownChange}
      value={status}
      trigger={trigger}
    />
  );
};

export default StatusDropdown;
