

export function ClientId() {
  return "7f1tojs0fv57e4mp8pt8oi5of"
  // let env = process.env.REACT_APP_REDIRECT
  // if(env === "local") {
  //   return "7f1tojs0fv57e4mp8pt8oi5of" // Vived_Dev_Local
  // }
  // return "5niuhkm07ncjdaqe7o34mksvtr" // Collection_Publishing
}


export function GoogleSignInUrl() {
  return `https://vived-users.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri=${RedirectUrl()}&response_type=code&client_id=${ClientId()}&identity_provider=Google`
}

export function RedirectUrl() {
  return "http://localhost:3000/"
  // let env = process.env.REACT_APP_API_HOST,
  //     redirectUrl = process.env.REACT_APP_REDIRECT

  // if(redirectUrl === "local") {
  //   return "http://localhost:3000/"
  // }
  // if(env === "prod") {
  //   return "https://collections.vivedlearning.com/"
  // }
  // if(env === "staging") {
  //   return "https://collections-staging.vivedlearning.com/"
  // }
  // if(env === "dev") {
  //   return "https://collections-dev.vivedlearning.com/"
  // }
}