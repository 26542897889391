

export const EndPoint = () => {

  if(process.env.REACT_APP_API_HOST === "local") {
    return "http://localhost:3001/"
  }
  if(process.env.REACT_APP_API_HOST === "test") {
    return "https://api-test.vivedlearning.com/"
  }
  if(process.env.REACT_APP_API_HOST === "staging") {
    return "https://api-staging.vivedlearning.com/"
  }
  if(process.env.REACT_APP_API_HOST === "prod") {
    return "https://api.vivedlearning.com/"
  }
  
  return "https://api-staging.vivedlearning.com/"
}
console.log("URL -- ",EndPoint())
export const apiClient = async (params) => {

  let headers = {
    'Content-Type': 'application/json'
  }

  if(params.userToken)
  {
    headers.Authorization = "Bearer " + params.userToken
  }

  const options = {
    method: params.method,
    headers
  };

  var url = new URL(params.path, EndPoint());

  if (params.query && Object.keys(params.query).length > 0) {
    Object.keys(params.query).forEach(key => url.searchParams.append(key, params.query[key]))
    options.params = { ...params.query }
  };
  if (params.data && Object.keys(params.data).length > 0) {
    options.body = JSON.stringify(params.data)
  };

  console.log('API OPTIONS', options, url);
  return await fetch(url, options)
  .then(async (response) => {
    return await response.json()
    .then((body) => {
      if(response.status === 200) {
        console.log('API RESPONSE', body);
        return {data: body}
      }
      else {
        throw body.message
      }
    })
  })
  .catch(err => {
    console.error('API ERROR', err)
    throw err
  })
};


export const customApiClient = async (params) => {

  let headers = {}

  if(params.userToken)
  {
    headers.Authorization = "Bearer " + params.userToken
    headers["Content-Type"] = 'application/json'
  }

  const options = {
    method: params.method,
    headers: params.allowHeaders ? headers : {}
  };

  var url = params.url

  if (params.query && Object.keys(params.query).length > 0) {
    Object.keys(params.query).forEach(key => url.searchParams.append(key, params.query[key]))
    options.params = { ...params.query }
  };

  if (params.data && params.method !== "GET" && params.method !== "get") {
    options.body = params.data
  };

  console.log('API OPTIONS', options, url);
  return await fetch(url, options)
  .then(async (response) => {
    if(params.expectingResponse) {
      return await response.json()
      .then((body) => {
        if(response.status === 200) {
          console.log('API RESPONSE', body);
          return {data: body}
        }
        else {
          throw body.message
        }
      })
    } else {
      if(response.status === 200) {
        return
      }
      else {
        throw response
      }
    }
  })
  .catch(err => {
    console.error('API ERROR', err)
    throw err
  })
};



