import { Auth } from "aws-amplify";
import * as CognitoCred from "./CognitoCredentials";
import store from "../store";
import Actions from "../actions";

export default class UserController {
  static use = new UserController();
  
  refreshToken = "";
  currentLoginStatus = "init";

  constructor() {
    UserController.use = this;
  }

  attachStore()
  {
    store.subscribe(this.handleLoginStateChange)
  }

  handleLoginStateChange = () => {
    const state = store.getState();
    if(this.currentLoginStatus !== state.User.loginStatus)
    {
      this.currentLoginStatus = state.User.loginStatus;
      if(this.currentLoginStatus === "isLoggedIn")
      {
        //User is logged in. Kick off a few api calls
        store.dispatch(Actions.Bundles.getAllBundles());
      } else if (this.currentLoginStatus === "isNotLoggedIn")
      {
        //User is not logged in. Clear some stuff
        store.dispatch(Actions.Bundles.clearBundleList());
      }
    }
  }


  GetUserDataFromAPIUser(user) {
    this.refreshToken = user.signInUserSession.refreshToken.token;

    let userData = {
      id: user.username,
      name: user.attributes.name,
      family_name: user.attributes.family_name,
      email: user.attributes.email,
      phone: user.attributes.phone_number ? user.attributes.phone_number : null,
      idToken: user.signInUserSession.idToken.jwtToken,
      groups: user.signInUserSession.idToken.payload["cognito:groups"],
      avatar: user.attributes["custom:avatar"]
    };

    return userData;
  }

  async CheckForUser() {
    if (
      [
        "/register",
        "/logic",
        "/recovery-password",
        "/recovery-confirmation",
        "/authcode"
      ].includes(window.location.pathname)
    ) {
      // Checking for a user during any of the stages of auth will cause errors
      return;
    }

    return await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(async _user => {
        console.log('USER', _user)
        UserController.use.refreshToken =
          _user.signInUserSession.refreshToken.token;

        return {
          id: _user.username,
          name: _user.attributes.name,
          family_name: _user.attributes.family_name,
          email: _user.attributes.email,
          phone: _user.attributes.phone_number
            ? _user.attributes.phone_number
            : null,
          idToken: _user.signInUserSession.idToken.jwtToken,
          groups: _user.signInUserSession.idToken.payload["cognito:groups"],
          avatar: _user.attributes["custom:avatar"]
        };
      })
      .catch(() => {
        return null;
      });
  }

  SignOut() {
    this._isLogedIn = false;
    this._fullName = "";
    this._email = "";
    this._phone = "";
    this.idtoken = "";
    this.refreshtoken = "";
    Auth.signOut().catch(err => {
      console.error(err);
    });
  }

  async RefreshUser() {
    return await fetch(
      "https://cognito-idp.us-east-1.amazonaws.com/oauth2/token",
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          "Content-Type": "application/x-amz-json-1.1"
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          ClientId: CognitoCred.ClientId(),
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: {
            REFRESH_TOKEN: UserController.use.refreshToken
          }
        })
      }
    )
      .then(async res => {
        return await res.json().then(data => {
          return data.AuthenticationResult.IdToken;
        }); // this will give jwt id and access tokens
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }
}
