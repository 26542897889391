import React from "react";
import "./PlayerCanvas.css";

const PlayerCanvas = ({canvasId}) => {
  return (
    <div className={"playerContainer"}>
      <canvas
        className="playerCanvas"
        id={canvasId}
      />
    </div>
  );
};

export default PlayerCanvas;
