import { Vector3 } from "@babylonjs/core/Maths/math";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";

export default class CollectionObjects {
  root; //the root node for this collection
  objectLookup; // Map <object name, transfrom>

  _player;

  constructor(player, root) {
    this._player = player;
    this.root = root;

    let children = root.getDescendants(true);
    this.objectLookup = new Map();
    children.forEach(child => {
      this.objectLookup.set(child.name, child);
    });
  }

  getObjectBounds(objectName) {
    if (this.objectLookup.has(objectName)) {
      const obj = this.objectLookup.get(objectName);
      const meshes = obj.getChildMeshes();

      if(meshes.length === 0)
      {
          console.warn("Mesh count is 0");
          return null;
      }
      
      let min = Vector3.Zero();
      let max = Vector3.Zero();

      meshes.forEach((mesh, i) => {
        let bb = mesh.getBoundingInfo().boundingBox;
        if (i === 0) {
          min = bb.minimumWorld.clone();
          max = bb.maximumWorld.clone();
        } else {
          min = Vector3.Minimize(min, bb.minimumWorld.clone());
          max = Vector3.Maximize(max, bb.maximumWorld.clone());
        }
      });

      let overallBounds = new BoundingInfo(min, max);
      return overallBounds.boundingSphere;
    } else {
      return null;
    }
  }
}
