import { takeEvery } from "redux-saga/effects";
import { putCollectionModel, updateCollectionModel, getCollectionBundle, getAllCollections, getACollection, getACollectionsData, patchCollectionMeta, getCollectionModelURL, patchCollectionData, putCollection } from "./collectionsSaga";
import { getBundleList, addCollectionToBundle, removeCollectionToBundle } from "./bundleSaga";
import * as TYPES from "../actions/types";
import { requestModule, upload, customRequestModule } from "./requestModule";
import { checkForUser, refreshUser, editUser } from "./user";
import { getPostModelUrl, uploadModel } from "./modelSagas";
import { postApp, getAllApps } from "./appSagas";


export function* mySaga() {
  try {
    /* COLLECTIONS */
    yield takeEvery(
      TYPES.COLLECTIONS_FETCH_A_COLLECTION,
      getACollection(requestModule)
    );
    yield takeEvery(
      TYPES.COLLECTIONS_BUNDLE_FETCH,
      getCollectionBundle(requestModule)
    );
    yield takeEvery(
      TYPES.COLLECTIONS_FETCH_ALL,
      getAllCollections(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_FETCH_A_COLLECTION_DATA,
      getACollectionsData(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_PATCH_COLLECTION_META,
      patchCollectionMeta(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_PATCH_COLLECTION_DATA,
      patchCollectionData(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_GET_MODEL_URL,
      getCollectionModelURL(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_UPDATE_COLLECTION_MODEL,
      updateCollectionModel(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_PUT_COLLECTION_MODEL,
      putCollectionModel(requestModule)
    );

    yield takeEvery(
      TYPES.COLLECTIONS_PUT_COLLECTION,
      putCollection(requestModule)
    );

    /* USERS */
    yield takeEvery(
      TYPES.BUNDLES_GET_ALL, 
      getBundleList(requestModule)
    );

    yield takeEvery(
      TYPES.ADD_COLLECTION_TO_BUNDLE,
      addCollectionToBundle(requestModule)
    );

    yield takeEvery(
      TYPES.REMOVE_COLLECTION_TO_BUNDLE,
      removeCollectionToBundle(requestModule)
    );

    /* USERS */
    yield takeEvery(TYPES.CHECK_FOR_USER, checkForUser());
    yield takeEvery(TYPES.REFRESH_USER, refreshUser());
    yield takeEvery(TYPES.EDIT_USER_DATA, editUser());

    /* MODELS */
    yield takeEvery(TYPES.MODELS_GET_POST_URL, getPostModelUrl(requestModule));
    yield takeEvery(TYPES.MODELS_POST_MODEL_TO_S3, uploadModel(customRequestModule));

    /* APPS */
    yield takeEvery(TYPES.APP_CREATE, postApp(requestModule));
    yield takeEvery(TYPES.APP_GETALL, getAllApps(requestModule));


    /* UPLOADS */
    yield takeEvery(TYPES.UNIVERSAL_UPLOAD_FILE, upload);
    
  } catch (err) {
    console.error("mySaga Error: ", err);
  }
}

export default mySaga;
