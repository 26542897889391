import { Vector3, Quaternion } from "@babylonjs/core/Maths/math";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";

export class LoadGLB {
  _OnSuccessCallback;
  _OnFailCallback;
  _player;

  _materialLookup;
  _duplicateMaterialsFound = false;

  constructor(player, OnSuccessCB, OnFailCB) {
    this._OnFailCallback = OnFailCB;
    this._OnSuccessCallback = OnSuccessCB;
    this._player = player;
  }

  LoadFromData(data) {
    let blob = new Blob([data]);
    var url = URL.createObjectURL(blob);
    this._doImport(url);
  }

  LoadFromURL(url) {
    console.log(url)
    this._doImport(url);
  }

  _doImport(url) {
    SceneLoader.ImportMesh(
      "", //Mesh names
      "",
      url, //asset
      this._player.scene, //Scene
      objects => {
        //success
        // console.log(objects)
        this._processModel(objects);
      },
      (p) => {
        // console.log(p)
      }, //Progress callback
      e => {
        console.error(e)
        this._OnFailCallback("Could not import the model");
      },
      ".glb" //Import plugin extention
    );
  }

  /*
  The end heirarchy should look like this
  - Collection Root (not included in the glb)
    -This Collection's root (actual root of this collection)
      -Child Transform
        -Child Mesh
      -Child Transform
        -Child Mesh
        -Child Mesh
        ...
      ...
  */

  _processModel(objects) {
    const root = objects[0];
    let importedCollectionRoot = root;
    while (importedCollectionRoot.name === "__root__") {
      importedCollectionRoot = importedCollectionRoot.getChildTransformNodes()[0];

      if (importedCollectionRoot == null) {
        this._OnFailCallback("Failed to find collection root");
        return;
      }
    }
    this._player.collectionRoot.reset();
    importedCollectionRoot.setParent(this._player.collectionRoot.transform);
    importedCollectionRoot.computeWorldMatrix();
    root.dispose();

    // get children transforms
    let children = importedCollectionRoot.getChildTransformNodes(true);

    //We need to ensure there are no duplicate names
    let childNames = [];
    this._materialLookup = {};
    this._duplicateMaterialsFound = false;

    //Setup each child
    for (let i = 0; i < children.length; i++) {
      let child = children[i];

      if (childNames.includes(child.name)) {
        //Bummer!
        this._OnFailCallback(`Found a duplicate child name in the file: ${child.name}`);
        return;
      }
      childNames.push(child.name);

      let childClass = child.getClassName();

      if (childClass === "TransformNode") {
        //This child is a split mesh object, meaning this object has numerous meshes. This is caused by multiple
        //materials or large meshes. We just need to make sure that any scaling is applied to the meshes, not the child transform
        let transformScale = child.scaling.clone();
        child.scaling = Vector3.One();

        //Make sure it has a quaterion for rotation
        if (!child.rotationQuaternion) {
          let eul = child.rotation;
          child.rotationQuaternion = Quaternion.RotationYawPitchRoll(
            eul.y,
            eul.x,
            eul.z
          );
        }
        child.computeWorldMatrix();

        let subMeshes = child.getChildTransformNodes(true);
        subMeshes.forEach(mesh => {
          this._checkDuplicateMaterials(mesh);
          mesh.scaling = transformScale.clone().multiply(mesh.scaling);
          mesh.computeWorldMatrix();
        });
      } else if (childClass === "Mesh" || childClass === "InstancedMesh") {
        //This child is just a mesh. We need to make a transform parent for it
        let newTransformNode = new TransformNode(child.name);
        newTransformNode.parent = child.parent;
        newTransformNode.position = child.position.clone();
        newTransformNode.scaling = Vector3.One();
        if (child.rotationQuaternion) {
          newTransformNode.rotationQuaternion = child.rotationQuaternion.clone();
        } else {
          let eul = child.rotation;
          newTransformNode.rotationQuaternion = Quaternion.RotationYawPitchRoll(
            eul.y,
            eul.x,
            eul.z
          );
        }
        newTransformNode.computeWorldMatrix();
        child.setParent(newTransformNode);
        child.computeWorldMatrix();
        this._checkDuplicateMaterials(child);
      } else {
        //If we hit this then we have run unto an child with an unexpected class.
        this._OnFailCallback(`Ran into an unexpected class while processing the mesh: ${childClass}`);
        return;
      }
    }

    if (this._duplicateMaterialsFound) {
      alert("Duplicate materials found. Attempting to fix by deleting duplicates and reassigning, but there's a chance it might not look right. See log for details.");
    }
    this._OnSuccessCallback(importedCollectionRoot);
  }

  _checkDuplicateMaterials(mesh) {
    if (mesh.material !== null && mesh.material !== undefined) {
      var currentMaterial = mesh.material;

      const lookupId = currentMaterial.name;

      if (this._materialLookup.hasOwnProperty(lookupId)) {
        // handle duplicate material         
        if (currentMaterial !== this._materialLookup[lookupId]) {
          console.log("Dupliate material name found: ", currentMaterial.name);

          mesh.material = this._materialLookup[lookupId];
          currentMaterial.dispose();
          this._duplicateMaterialsFound = true;
        }
      }
      else {
        // add new material to lookup      
        this._materialLookup[lookupId] = mesh.material;
      }
    }
  }
  
}
