import React from "react";
import { Header, Button, Message, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import UserController from "../utils/UserController";
import Actions from "../actions";
import Login from "../components/Login/Login";

class User extends React.Component {
  
  ClickLogout = () => {
    UserController.use.SignOut();
    this.props.logoutUser();
  };

  renderUserInfo() {
    return (
      <>
        <Header>You are logged in!</Header>
        <Button onClick={this.ClickLogout}>Log Out</Button>
      </>
    );
  }

  renderSpinner() {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          Checking for login
        </Message.Content>
      </Message>
    );
  }

  render() {
    if (this.props.loginStatus === "isLoggedIn") {
      return this.renderUserInfo();
    } else if (this.props.loginStatus === "isNotLoggedIn") {
      return <Login />
    } else {
      return this.renderSpinner();
    }
  }
}

const MapStateToProps = state => {
  const loginStatus = state.User.loginStatus;

  return {
    loginStatus
  };
};

export default connect(MapStateToProps, {
  logoutUser: () => Actions.User.logOut()
})(User);
