import {
  call,
  select
} from 'redux-saga/effects';
import { apiClient, customApiClient, EndPoint } from '../utils/apiClient';


export function* requestModule(action) {
  try {
    const state = yield select();
    let userToken = state.User.loginStatus === "isLoggedIn" ? state.User.idToken : null;

    const params = {
      query: {},
      method: 'get',
      data: {},
      path: '',
      ...action,
      userToken
    };
    console.log('REQUEST PARAMS', params);
    const response = yield call(apiClient, params);

    return response;
  } catch (e) {
    throw(e);
  }
}

export function* customRequestModule(action) {
  try {
    const state = yield select();
    let userToken = state.User.loginStatus === "isLoggedIn" ? state.User.idToken : null;

    const params = {
      query: {},
      method: 'get',
      data: {},
      path: '',
      ...action,
      userToken
    };
    console.log('REQUEST PARAMS', params);
    const response = yield call(customApiClient, params);

    return response;
  } catch (e) {
    throw(e);
  }
}

export function* upload(action) {
  try {
    const { folder, file, filename } = action.data;
    const state = yield select();
    let userToken = state.User.loginStatus === "isLoggedIn" ? state.User.idToken : null;
    
    const params = {
      url: `${EndPoint()}upload/${folder}/${filename}`,
      method: 'POST',
      data: file,
      userToken,
      allowHeaders: true,
    };

    console.log("UPLOADING")
    console.log('REQUEST PARAMS', params);
    yield call(customApiClient, params);
    console.log("DONE_UPLOADING")
    
    if (action.cb) {
      yield call(action.cb, filename);
    }
    return filename
  } catch (e) {
    if (action.cb) {
      yield call(action.cb, null);
    }
    return null;
  }
}

export default {
  requestModule,
  upload
};
