import React from "react";
import Home from "./Home";
import AppBar from "../components/AppBar/AppBar";
import { Switch, Route, Router } from "react-router-dom";
import Collections from "./Collections";
import NewCollection from "./NewCollection/NewCollection";
import NewApp from "./NewApp/NewApp";
import "./App.css";
import Actions from "../actions";
import { connect } from "react-redux";
import Amplify from "aws-amplify";
import User from "./User";
import UserController from "../utils/UserController";
import EditCollection from "./EditCollection";
import Config from "../aws-exports";
import history from "../history";
import Collection_AddModel from "./Collection_AddModel";
import Collection_EditModel from "./Collection_EditModel";

Amplify.configure(Config(window.location.pathname));

class App extends React.Component {
  componentDidMount() {
    UserController.use.attachStore();

    if (this.props.loginStatus === "init") {
      this.props.checkForUser();
    }
  }

  render() {
    return (
      <div className={"main-container"}>
        <Router history={history}>
          <AppBar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/collections" exact component={Collections} />

            <Route
              path="/collections/:collection_id"
              exact
              component={EditCollection}
            />

            <Route path="/new" exact component={NewCollection} />
            <Route path="/new/app" exact component={NewApp} />

            <Route path="/user" exact component={User} />
            <Route
              path="/collections/:collection_id/add"
              exact
              component={Collection_AddModel}
            />
            <Route
              path="/collections/:collection_id/model/:model_id"
              exact
              component={Collection_EditModel}
            />
            }
          </Switch>
        </Router>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const loginStatus = state.User.loginStatus;

  return {
    loginStatus
  };
};

export default connect(MapStateToProps, {
  checkForUser: () => Actions.User.checkForUser()
})(App);
