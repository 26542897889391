import React from "react";
import { Button, Menu, Grid, Form, Image, Label } from "semantic-ui-react";
import PlayerCanvas from "../../components/PlayerCanvas/PlayerCanvas";
import WebGLPlayer from "../../player/WebGLPlayer";
import UTILITIES from "../../utils/Utilities";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import Actions from "../../actions";
import * as uuid from "uuid"

const playerCanvasID = "webGL_Canvas";

class Meta extends React.Component {
  player = null;

  state = {
    image: null,
    file: {},
    name: "",
    title: "",
    desc: "",
    initialView: {},
    initialViewClicked: false,
    submitting: false,
  };

  onSubmit = () => {
    this.props.collection.id = uuidv4();
    this.props.collection.name = this.state.name;
    this.props.collection.image = this.state.image;
    this.props.collection.title = { en: this.state.title };
    this.props.collection.description = { en: this.state.desc };
    this.props.collection.initialView = this.state.initialView;

    this.setState({ submitting: true });

    const modelKeys = Object.keys(this.props.collection.models),
      model = this.props.collection.models[modelKeys[0]]

    this.props.uploadFile(this.state.file, "Thumbnails", (newFileName) => {
      if (newFileName) {
        this.props.collection.image = newFileName

        this.props.uploadModel(model.filename, model.file, (result) => {
          if (result.success) {
            this.props.putCollection(this.props.collection, resp => {
              if (resp.success) {
                this.props.history.push(`collections/${this.props.collection.id}`);
              } else {
                this.props.errorMessage(resp.msg);
              }
            });
          } else {
            this.setState({
              error: true,
              errorMessage: result.msg
            });
          }
        })
      } else {
        this.setState({
          error: true,
          errorMessage: "Uploading Image failed"
        });
      }
    })
  };

  componentDidMount() {
    this.makePlayer();
  }

  componentWillUnmount() {
    this.player.dispose();
  }

  ImageToBlob(file) {
    return new Promise((win, fail) => {
      let reader = new FileReader()

      reader.onload = function (e) {
        if (e.target.result) {
          win(e.target.result)
        } else {
          fail("")
        }
      };

      reader.readAsDataURL(file)
    })
  }

  doScreenshot = () => {
    if (this.player) {
      this.player.takeScreenShot(imageData => {
        fetch(imageData)
          .then(res => res.blob())
          .then(async (blob) => {
            const imageURL = URL.createObjectURL(blob);
            this.setState({
              image: imageURL,
              file: await this.ImageToBlob(blob),
              browsedFile: null
            });
          });
      });
    }
  };

  onFileBrowseChange = async (e) => {
    var url = URL.createObjectURL(e.target.files[0]);
    this.setState({
      image: url,
      file: await this.ImageToBlob(e.target.files[0]),
      screenshotFile: null
    });
  };

  doFileDownload = () => {
    if (this.state.image) {
      var link = document.createElement("a");
      link.href = this.state.image;
      link.download = `newCollectionThumb.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  clickSetInitialView = () => {
    this.setState({ initialViewClicked: true });
    this.setInitialView();
  }

  setInitialView = () => {
    const view = this.player.getCurrentView();
    this.setState({ initialView: view });
  }

  makePlayer() {
    let playerCanvas = document.getElementById(playerCanvasID);
    if (playerCanvas) {
      this.player = new WebGLPlayer(playerCanvas);
      this.player.loadGLBFromURL(
        this.props.modelURL,
        () => {
          const labels = {};
          this.player.collectionObjects.objectLookup.forEach((t, objName) => {
            const label = UTILITIES.ObjectNameToLabel(objName);
            labels[objName] = label;
          });

          // init initial view
          this.setInitialView();

          //Success
          this.setState({
            labels,
            isLoading: false
          });
        },
        errorMsg => {
          //Fail
          this.props.errorMessage(errorMsg);
        }
      );
    }
  }

  renderPlayer() {
    return (
      <div style={{ height: "500px", width: "500px" }}>
        <PlayerCanvas canvasId={playerCanvasID} />
      </div>
    );
  }

  renderInitialViewSet() {    
    if (this.state.initialViewClicked) {
      return (
        <Label color="green" horizontal>
          SET
        </Label>
      );
    }
    else {
      return null;
    }
  }

  renderMeta() {
    const downloadDisable = this.state.image ? false : true;
    return (
      <>
        <Grid.Row>
          <Grid>
            <Grid.Column width={8}>
              <Image
                src={
                  this.state.image
                    ? this.state.image
                    : "https://react.semantic-ui.com/images/wireframe/image.png"
                }
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Button.Group vertical labeled icon>
                <Button
                  icon="image"
                  content="Screenshot"
                  onClick={this.doScreenshot}
                />
                <Button
                  icon="upload"
                  content="Browse"
                  name="upload_image"
                  as="label"
                  htmlFor="file"
                  type="button"
                />
                <Button
                  icon="download"
                  content="Download"
                  disabled={downloadDisable}
                  onClick={this.doFileDownload}
                />
                <input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  accept="image/png"
                  onChange={this.onFileBrowseChange}
                />
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <Grid.Row>
          <br />
          <Button
            icon="anchor"
            content="Set Initial View"
            name="setInitialView"
            onClick={this.clickSetInitialView}
          />
          {this.renderInitialViewSet()}
          <br />
        </Grid.Row>
        <Grid.Row>
          <Form onSubmit={this.onSubmit} loading={this.state.submitting}>
            <Form.Input
              fluid
              required
              label="Internal Name"
              placeholder="Name"
              onChange={(e, d) => this.setState({ name: d.value })}
            />
            <Form.Input
              fluid
              required
              label="Title"
              placeholder="Title"
              onChange={(e, d) => this.setState({ title: d.value })}
            />

            <Form.TextArea
              label="Description"
              placeholder="Describe this collection..."
              onChange={(e, d) => this.setState({ desc: d.value })}
            />
            <Form.Button>Submit</Form.Button>
          </Form>
        </Grid.Row>
      </>
    );
  }

  render() {
    return (
      <>
        <Menu secondary>
          <Menu.Item header>Setup Meta</Menu.Item>
        </Menu>
        <Grid relaxed columns={2}>
          <Grid.Column>{this.renderPlayer()}</Grid.Column>
          <Grid.Column>{this.renderMeta()}</Grid.Column>
        </Grid>
      </>
    );
  }
}


export default connect(null, {
  putCollection: (collection, callback) =>
    Actions.Collections.putCollection(collection, callback),
  uploadFile: (file, folder, cb) => {
    if (file) {
      const filename = `${uuid.v4()}.thumbnail`
      return Actions.Universal.uploadFile({ file, folder, filename }, cb)
    } else {
      cb("")
      return { type: "NO ACTION" }
    }
  },
  uploadModel: (filename, modelData, cb) => Actions.Model.getModelPostUrl(filename, modelData, cb)
})(Meta);
