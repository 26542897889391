import { Vector3, Quaternion } from "@babylonjs/core/Maths/math";

export default class UTILITIES {
  static Vector3ToString(vector) {
    return `${vector.x},${vector.y},${vector.z}`;
  }

  static StringToVector3(vectorString) {
    let split = vectorString.split(",");
    if (split.length !== 3) {
      console.error(
        "Could not convert the following string to Vector3: " + vectorString
      );
      return null;
    }

    return new Vector3(split[0], split[1], split[2]);
  }

  static QuaternionToString(quaternion) {
    return `${quaternion.x},${quaternion.y},${quaternion.z},${quaternion.w}`;
  }

  static StringToQuaternion(quaternionString) {
    let split = quaternionString.split(",");
    if (split.length !== 4) {
      console.error(
        "Could not convert the following string to Quaternion: " +
          quaternionString
      );
      return null;
    }

    return new Quaternion(split[0], split[1], split[2], split[3]);
  }

  static ObjectNameToLabel(objName) {
    let rVal = objName.replace(/[_]/g, " "); //Replate _ with a space
    rVal = rVal.replace(/[0-9]/g, ""); //Remove all numbers
    rVal = rVal.replace("()", ""); //Get rid of () that sometimes get added during modeler exports

    if (
      rVal.toLocaleLowerCase().substr(rVal.length - 2, 2) === " r" ||
      rVal.toLocaleLowerCase().substr(rVal.length - 2, 2) === " l"
    ) {
      rVal = rVal.substr(0, rVal.length - 2); //Get rid of L and R on the end of object labels
    }

    rVal = rVal.trim(); //Trim off white spaces
    rVal = rVal.charAt(0).toUpperCase() + rVal.slice(1); //Capitalize the first character

    return rVal;
  }
}
