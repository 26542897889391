import * as TYPES from "./types";

const createApp = (appData, callback) => {
  return{
    type: TYPES.APP_CREATE,
    callback,
    appData
  };
}

const getAllApps = (callback) => {
  return{
    type: TYPES.APP_GETALL,
    callback
  };
}

const addAllAppsToList = (allApps, callback) => {
  return{
    type: TYPES.APP_ADD_ALL_TO_LIST,
    callback,
    allApps
  };
}


const updateAppInList = (app, callback) => {
  return{
    type: TYPES.UPDATE_APP_IN_LIST,
    app
  };
}

export default {
  createApp,
  updateAppInList,
  getAllApps,
  addAllAppsToList
}