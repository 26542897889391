import React from "react";
import { Languages } from "../../utils/Languages";
import { Dropdown } from "semantic-ui-react";

const LanguageDropdown = ({language, onChange}) => {
    return (
        <Dropdown
          placeholder="Language"
          options={Languages.GetLanguagesForDropdown()}
          onChange={onChange}
          value={language}
          search
          selection
        />
      );
}

export default LanguageDropdown;