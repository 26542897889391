import React from "react";
import { connect } from "react-redux";
import Actions from "../actions";
import {
  Message,
  Icon,
  Label,
  Button,
  Menu,
  Segment,
  Table
} from "semantic-ui-react";
import LoginState from "../components/LoginState/LoginState";
import StatusDropdown from "../components/Status/StatusDropdown";
import PlayerCanvas from "../components/PlayerCanvas/PlayerCanvas";
import WebGLPlayer from "../player/WebGLPlayer";
import { ExportGLB } from "../player/ExportGLB";
import { Link } from "react-router-dom";
import { LODLabel } from "../components/LOD/LOD";
import { v4 as uuidv4 } from 'uuid';

const playerCanvasID = "webGL_Canvas";

class Collection_AddModel extends React.Component {
  player = null;

  comparedObjectNameList = [];

  state = {
    isFetching: true,
    error: false,
    errorMessage: "",
    showSaveSuccess: false,
    isSaving: false,
    status: "draft",
    validationFailed: false
  };

  componentDidMount() {
    if (!this.props.collection) {
      this.getCollections();
    } else {
      this.setState({ isFetching: false });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  getCollections() {
    this.setState({ isFetching: true });
    this.props.fetchAllCollections(resp => {
      if (resp.success) {
        this.setState({
          isFetching: false
        });
      } else {
        this.setState({
          isFetching: false,
          error: true,
          errorMessage: resp.msg
        });
      }
    });
  }

  componentDidUpdate() {
    if (!this.player && !this.state.isFetching && !this.state.error) {
      this.makePlayer();
    }
  }

  makePlayer() {
    let playerCanvas = document.getElementById(playerCanvasID);
    if (playerCanvas) {
      this.player = new WebGLPlayer(playerCanvas);
      this.player.loadGLBFromURL(
        this.props.blobURL,
        () => {
          //Success
          this.setState({ isFetching: false });
          // this.validateModel();
        },
        errorMsg => {
          //Fail
          this.setState({
            isFetching: false,
            error: true,
            errorMessage: errorMsg
          });
        }
      );
      this.player.setShowInspector(true);
    }
  }

  validateModel() {
    if (!this.props.collection.objectList) {
      this.setState({
        error: true,
        errorMessage: "Could not retrieve collection object list"
      });
    }

    const collectionList = this.props.collection.objectList;
    const modelObjectList = [];
    this.player.collectionObjects.objectLookup.forEach((t, objName) => {
      modelObjectList.push(objName);
    });

    let listsMatch = true;
    if (modelObjectList.length !== collectionList.length) {
      listsMatch = false;
    } else {
      collectionList.forEach(name => {
        if (!modelObjectList.includes(name)) {
          listsMatch = false;
        }
      });
    }

    if (!listsMatch) {
      const completeListOfNames = [...collectionList];
      modelObjectList.forEach(modelObjectName => {
        if (!completeListOfNames.includes(modelObjectName)) {
          completeListOfNames.push(modelObjectName);
        }
      });

      completeListOfNames.forEach(name => {
        const isInCollection = collectionList.includes(name);
        const isInModel = modelObjectList.includes(name);
        this.comparedObjectNameList.push({
          name,
          isInCollection,
          isInModel
        });
      });

      this.setState({ validationFailed: true });
    }
  }

  doSave = () => {
    if (this.player) {
      this.setState({ isSaving: true });
      ExportGLB(this.player, exportResp => {
        if (exportResp.success) {
          let filename = uuidv4() + "." + this.props.format
          let file = new File([exportResp.file], filename) 
          this.props.uploadModel(filename, file, (result) => {
            if(result.success) {
              this.props.putCollectionModel(
                this.props.collection_id,
                this.props.lod,
                this.props.format,
                filename,
                this.state.status,
                this.props.version,
                addResp => {
                  this.setState({ isSaving: false });
                }
              );
            } else {
              this.setState({
                error: true,
                errorMessage: exportResp.message
              });
            }
          })
        } else {
          this.setState({
            error: true,
            errorMessage: exportResp.message
          });
        }
      });
    }
  };

  onStatusDropdownChange = (e, d) => {
    this.setState({ status: d.value });
  };

  renderFailedValidation = () => {
    return (
      <Segment>
        <Message negative>
          <Message.Header>Model failed validation</Message.Header>
          <Message.Content>
            All object names in model must match exactly to object names in
            other models for this collection
          </Message.Content>
        </Message>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Object Name</Table.HeaderCell>
              <Table.HeaderCell>Is in Collection</Table.HeaderCell>
              <Table.HeaderCell>Is in Model</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.comparedObjectNameList.map(obj => {
              return (
                <Table.Row key={obj.name}>
                  <Table.Cell>{obj.name}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {obj.isInCollection ? (
                      <Icon color="green" name="checkmark" size="large" />
                    ) : (
                      <Icon color="red" name="x" size="large" />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {obj.isInModel ? (
                      <Icon color="green" name="checkmark" size="large" />
                    ) : (
                      <Icon color="red" name="x" size="large" />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  };

  renderContent = () => {
    return (
      <div>
        <Menu attached="top">
          <Menu.Item>
            <Button as={Link} to={`/collections/${this.props.collection_id}?tab=Models`}>
              <Icon name="left arrow" />
              Back to Collection
            </Button>
          </Menu.Item>

          <Menu.Item>
            <StatusDropdown
              status={this.state.status}
              onDropdownChange={this.onStatusDropdownChange}
            />
            <LODLabel lod={this.props.lod} />
            <Label>Version: {this.props.version}</Label>
          </Menu.Item>

          <Menu.Item position="right">
            <Button
              positive
              onClick={this.doSave}
              loading={this.state.isSaving}
              disabled={this.state.validationFailed}
            >
              Save Changes
            </Button>
            {this.state.showSaveSuccess ? (
              <Label basic color="green" pointing="left">
                Saved!
              </Label>
            ) : null}
          </Menu.Item>
        </Menu>

        {this.state.validationFailed ? this.renderFailedValidation() : null}

        <Segment attached="bottom">
          <div style={{ height: "calc(100vh - 200px)" }}>
            <PlayerCanvas canvasId={playerCanvasID} />
          </div>
        </Segment>
      </div>
    );
  };

  render() {
    if (this.props.loginStatus === "isLoggedIn") {
      if (this.state.error) {
        return (
          <Message negative>
            <Message.Header>Uh oh...</Message.Header>
            <p>{this.state.errorMessage}</p>
          </Message>
        );
      } else if (this.state.isFetching) {
        return (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Just one second</Message.Header>
              We are fetching that collections for you...
            </Message.Content>
          </Message>
        );
      } else {
        return this.renderContent();
      }
    } else {
      return <LoginState loginStatus={this.props.loginStatus} />;
    }
  }
}

const MapStateToProps = (state, passedProps) => {
  const loginStatus = state.User.loginStatus;
  const collection_id = passedProps.match.params.collection_id;

  let collection = null;

  if (state.collections.list.hasOwnProperty(collection_id)) {
    collection = state.collections.list[collection_id];
  }
  const params = new URL(document.location).searchParams;

  const file = params.get("file");
  const blobURL = params.get("url");
  const format = "glb"
  const lod = params.get("lod");
  const version = params.get("version");

  return {
    collection_id,
    loginStatus,
    collection,
    file,
    blobURL,
    lod,
    format,
    version
  };
};

export default connect(MapStateToProps, {
  fetchAllCollections: callback =>
    Actions.Collections.fetchAllCollections(callback),
  putCollectionModel: (
    collection_id,
    format,
    version,
    status,
    lod,
    blobURL,
    callback
  ) =>
    Actions.Collections.putCollectionModel(
      collection_id,
      format,
      version,
      status,
      lod,
      blobURL,
      callback
    ),
  uploadModel: (file, modelData, cb) => Actions.Model.getModelPostUrl(file, modelData, cb)
})(Collection_AddModel);
