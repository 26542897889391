import { GLTF2Export } from "@babylonjs/serializers/glTF";
import { Vector3, Quaternion } from "@babylonjs/core/Maths/math";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";

export const ExportGLB = (player, callback) => {
  //Store current position
  const initialPos = player.collectionRoot.transform.position.clone();
  const initialScale = player.collectionRoot.transform.scaling.clone();
  const initialRotation = player.collectionRoot.transform.rotationQuaternion.clone();
  const initialParent = player.collectionObjects.root.parent;

  //Zero out the collection root
  player.collectionRoot.setTRS(
    Vector3.Zero(),
    Quaternion.Identity(),
    new Vector3(1, 1, 1)
  );
  player.collectionRoot.transform.computeWorldMatrix();

  //Make our root for export
  const root = new TransformNode("__root__", player.scene);
  root.position = new Vector3(0, 0, 0);
  root.rotationQuaternion = new Quaternion(0, 1, 0, 0);
  root.scaling = new Vector3(1, 1, -1);
  root.computeWorldMatrix();

  //Re-arrange the heirarchy
  player.collectionObjects.root.setParent(root);
  player.collectionObjects.root.computeWorldMatrix();

  let options = {
    shouldExportNode: function (node) {
      if (node === player.collectionObjects.root) return true;
      else if (node.isDescendantOf(player.collectionObjects.root)) return true;
      else return false;
    },
  };

  GLTF2Export.GLBAsync(player.scene, "fileName", options)
    .then(async (glb) => {
      //glb.downloadFiles();
      const objectURL = URL.createObjectURL(glb.glTFFiles["fileName.glb"]);
      callback({
        success: true,
        modelURL: objectURL,
        file: glb.glTFFiles["fileName.glb"],
      });
      restoreScene();
    })
    .catch((e) => {
      callback({ success: false, message: e.message });
      restoreScene();
    });

  const restoreScene = () => {
    player.collectionObjects.root.setParent(initialParent);
    player.collectionRoot.setTRS(
      initialPos,
      initialRotation,
      initialScale
    );
    root.dispose();
  };
};
