import React from "react";
import { Link } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";
import { connect } from "react-redux";

const AppBar = props => {
  let loginOrOut = <></>;
  if (props.loginStatus === "isLoggedIn") {
    loginOrOut = (
      <Menu.Item position="right">
        <Image as={Link} to="/user" src={props.avatar} avatar />
      </Menu.Item>
    );
  }

  return (
    <Menu pointing>
      <Link to="/" className="item">
        VIVED Collections
      </Link>
      {loginOrOut}
    </Menu>
  );
};

const MapStateToProps = state => {
  const loginStatus = state.User.loginStatus;

  let avatar = "";
  if (loginStatus === "isLoggedIn") {
    avatar = state.User.avatar;
  }
  return {
    loginStatus,
    avatar
  };
};
export default connect(MapStateToProps)(AppBar);
