import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { Vector3, Quaternion, Space, Plane, Matrix } from "@babylonjs/core/Maths/math";
import { Tools } from "@babylonjs/core/Misc/tools";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";

export class CollectionRoot
{
  transform;

  constructor(player)
  {
    this.transform = new TransformNode("CollectionObjectRoot", player.scene);
    this.reset();
  }

  reset() {
    this.transform.rotationQuaternion = Quaternion.Identity();
    this.transform.position = Vector3.Zero();
    this.transform.scaling = new Vector3(1, 1, 1);
  }

  setTRS(position, rotation, scale)
  {
    this.transform.rotationQuaternion = rotation.clone();
    this.transform.position = position.clone();
    this.transform.scaling = scale.clone();
    this.transform.computeWorldMatrix();
  }

  autoCenterOnCollection = () =>
  {
    let meshes = this.transform.getChildMeshes();
    if (meshes.length === 0) {
      console.warn("No Meshes!");
    }

    let min = Vector3.Zero();
    let max = Vector3.Zero();

    meshes.forEach((mesh, i) => {
      let bb = mesh.getBoundingInfo().boundingBox;
      if (i === 0) {
        min = bb.minimumWorld.clone();
        max = bb.maximumWorld.clone();
      } else {
        min = Vector3.Minimize(min, bb.minimumWorld.clone());
        max = Vector3.Maximize(max, bb.maximumWorld.clone());
      }
    });

    let overallBounds = new BoundingInfo(min, max);
    let overallSphere = overallBounds.boundingSphere;

    let newScale = 0.2 / overallSphere.radiusWorld; //In our world a radius of 0.2 fits nicely
    this.transform.scaling = new Vector3(newScale, newScale, newScale);
  }
}

export class CollectionInput_RightClickToOrbit {
  _noPreventDefault;
  _isAttached = false;
  _collectionRoot;
  _scene;

  _doOrbit = false;
  _initialPickX;
  _initialPickY;
  _helperRoot;
  _initialCollectionParent;

  rotationSensitivity = 0.005;

  constructor(scene, root) {
    this._scene = scene;
    this._collectionRoot = root;
  }

  onPointerDown = evt => {
    if(evt.button !== 2)
    {
      return;
    }

    if (!this._noPreventDefault) {
      evt.preventDefault();
    }

    this._initialPickX = this._scene.pointerX;
    this._initialPickY = this._scene.pointerY;

    let pick = this._scene.pick(this._scene.pointerX, this._scene.pointerY);
    if (pick.hit) {
      this._helperRoot.position = pick.pickedPoint;
    } else {
      this._helperRoot.position = Vector3.Zero();
    }
    this._helperRoot.rotationQuaternion = Quaternion.Identity();

    this._initialCollectionParent = this._collectionRoot.parent;
    this._collectionRoot.setParent(this._helperRoot);

    this._doOrbit = true;
  };

  onPointerUp = evt => {
    if(this._doOrbit)
    {
      if (!this._noPreventDefault) {
        evt.preventDefault();
      }
      this._collectionRoot.setParent(this._initialCollectionParent);
      this._doOrbit = false;
    }
  };

  //Required by input manager
  getTypeName = function() {
    return "CollectionInput_RightClickToOrbit";
  };
  //Required by input manager
  getSimpleName = function() {
    return "clickToOrbit";
  };

  attachControl = function(element, noPreventDefault) {
    this._noPreventDefault = noPreventDefault;

    if (!this._isAttached) {
      element.addEventListener("pointerdown", this.onPointerDown, false);
      element.addEventListener("pointerup", this.onPointerUp, false);
      element.addEventListener("mouseout", this.onPointerUp, false);

      this._helperRoot = new TransformNode("_orbitHelperNode", this._scene);
      this._helperRoot.scaling = Vector3.One();
      this._helperRoot.position = Vector3.Zero();
      this._helperRoot.rotationQuaternion = Quaternion.Identity();

      this._isAttached = true;
    }
  };

  detachControl = function(element) {
    if (this._isAttached) {
      element.removeEventListener("pointerdown", this.onPointerDown);
      element.removeEventListener("pointerup", this.onPointerUp);
      element.removeEventListener("mouseout", this.onPointerUp);
      this._helperRoot.dispose();
      this._isAttached = false;
    }
  };

  checkInputs = function() {
    if (this._doOrbit) {
      let deltaX = this._scene.pointerX - this._initialPickX;
      let deltaY = this._scene.pointerY - this._initialPickY;

      this._helperRoot.rotationQuaternion = Quaternion.Identity();

      if(Math.abs(deltaX) > Math.abs(deltaY))
      {
        this._helperRoot.rotate(Vector3.Down(), deltaX*this.rotationSensitivity, Space.WORLD);
      } else
      {
        this._helperRoot.rotate(Vector3.Left(), deltaY*this.rotationSensitivity, Space.WORLD);
      }
      
      return;
    }
  };
}

export class CollectionInput_LeftClickToPan {
  _noPreventDefault;
  _isAttached = false;
  _collectionRoot;
  _scene;

  _doPan = false;
  _peelPlane;
  _initialPick;
  _initialPosition;

  constructor(scene, root) {
    this._scene = scene;
    this._collectionRoot = root;
  }

  onPointerDown = evt => {
    if(evt.button !== 0)
    {
      return;
    }

    if (!this._noPreventDefault) {
      evt.preventDefault();
    }

    let planePos = Vector3.Zero();
    let pick = this._scene.pick(this._scene.pointerX, this._scene.pointerY);
    if (pick.hit) {
      planePos = pick.pickedPoint;
    }

    this._peelPlane = Plane.FromPositionAndNormal(
      planePos,
      Vector3.Backward()
    );


    const ray = this._scene.createPickingRay(
      this._scene.pointerX,
      this._scene.pointerY,
      Matrix.Identity(),
      this.camera
    );

    const depth = ray.intersectsPlane(this._peelPlane);
    if (!depth) return;
    this._initialPick = ray.direction.scale(depth).add(ray.origin);

    this._initialPosition = this._collectionRoot.position.clone();

    this._doPan = true;
  };

  onPointerUp = evt => {
    if(this._doPan)
    {
      if (!this._noPreventDefault) {
        evt.preventDefault();
      }
      this._doPan = false;
      this._peelPlane = null;
    }
  };

  //Required by input manager
  getTypeName = function() {
    return "CollectionInput_LeftClickToPan";
  };
  //Required by input manager
  getSimpleName = function() {
    return "clickToPan";
  };

  attachControl = function(element, noPreventDefault) {
    this._noPreventDefault = noPreventDefault;

    if (!this._isAttached) {
      element.addEventListener("pointerdown", this.onPointerDown, false);
      element.addEventListener("pointerup", this.onPointerUp, false);
      element.addEventListener("mouseout", this.onPointerUp, false);

      this._isAttached = true;
    }
  };

  detachControl = function(element) {
    if (this._isAttached) {
      element.removeEventListener("pointerdown", this.onPointerDown);
      element.removeEventListener("pointerup", this.onPointerUp);
      element.removeEventListener("mouseout", this.onPointerUp);

      this._isAttached = false;
    }
  };

  checkInputs = function() {
    if (this._doPan) {
      const ray = this._scene.createPickingRay(
        this._scene.pointerX,
        this._scene.pointerY,
        Matrix.Identity(),
        this.camera
      );

      const depth = ray.intersectsPlane(this._peelPlane);
      if (!depth) return;
      const hitPoint = ray.direction.scale(depth).add(ray.origin);
      const delta = hitPoint.subtract(this._initialPick);

      this._collectionRoot.position = this._initialPosition.add(delta);
      return;
    }
  };
}

export class CollectionInput_ClickToCenter {
  _noPreventDefault;
  _isAttached = false;
  _collectionRoot;
  _scene;

  constructor(scene, root) {
    this._scene = scene;
    this._collectionRoot = root;
  }

  onDoubleClick = evt => {
    if (!this._noPreventDefault) {
      evt.preventDefault();
    }

    let pick = this._scene.pick(this._scene.pointerX, this._scene.pointerY);
    if (pick.hit) {
      let newPos = this._collectionRoot.position.subtract(pick.pickedPoint);
      this._collectionRoot.position = newPos;
    }
  };

  //Required by input manager
  getTypeName = function() {
    return "CollectionInput_ClickToCenter";
  };
  //Required by input manager
  getSimpleName = function() {
    return "clickToCenter";
  };

  attachControl = function(element, noPreventDefault) {
    this._noPreventDefault = noPreventDefault;

    if (!this._isAttached) {
      element.addEventListener("dblclick", this.onDoubleClick, false);
      this._isAttached = true;
    }
  };

  detachControl = function(element) {
    if (this._isAttached) {
      element.removeEventListener("dblclick", this.onDoubleClick);
      this._isAttached = false;
    }
  };

  checkInputs = function() {};
}

export class CollectionInput_RollZoom {
  _totalRoll = 0;
  _noPreventDefault;
  _isAttached = false;
  _helperRoot;
  _scene;
  _collectionRoot;

  smallestScale = 0.0001;
  largestScale = 10;

  constructor(scene, root) {
    this._scene = scene;
    this._collectionRoot = root;
  }

  onRoll = evt => {
    let rollDelta = evt.deltaY > 0 ? -0.1 : 0.1;
    this._totalRoll += rollDelta;

    if (!this._noPreventDefault) {
      evt.preventDefault();
    }

    let pick = this._scene.pick(this._scene.pointerX, this._scene.pointerY);
    if (pick.hit) {
      this._helperRoot.position = pick.pickedPoint;
    } else {
      this._helperRoot.position = Vector3.Zero();
    }
  };

  //Required by input manager
  getTypeName = function() {
    return "CollectionInput_RollZoom";
  };
  //Required by input manager
  getSimpleName = function() {
    return "roll";
  };

  attachControl = function(element, noPreventDefault) {
    this._noPreventDefault = noPreventDefault;

    if (!this._isAttached) {
      element.addEventListener("wheel", this.onRoll, false);

      this._helperRoot = new TransformNode("_rollHelperNode", this._scene);
      this._helperRoot.scaling = Vector3.One();
      this._helperRoot.position = Vector3.Zero();
      this._helperRoot.rotationQuaternion = Quaternion.Identity();

      this._isAttached = true;
    }
  };

  detachControl = function(element) {
    if (this._isAttached) {
      element.removeEventListener("wheel", this.onRoll);
      this._helperRoot.dispose();
      this._isAttached = false;
    }
  };

  checkInputs = function() {
    if (this._totalRoll !== 0) {
      let vCurrentScale = this._collectionRoot.scaling.clone();
      let nCurrentScale = vCurrentScale.x;
      let nNewScale = nCurrentScale + this._totalRoll * nCurrentScale;

      if (nNewScale < this.smallestScale) {
        nNewScale = this.smallestScale;
      } else if (nNewScale > this.largestScale) {
        nNewScale = this.largestScale;
      }

      let vNewScale = new Vector3(nNewScale, nNewScale, nNewScale);

      this._helperRoot.scaling = vCurrentScale;
      let originalParent = this._collectionRoot.parent;
      this._collectionRoot.setParent(this._helperRoot);
      this._helperRoot.scaling = vNewScale;
      this._collectionRoot.setParent(originalParent);    

      this._totalRoll = 0;
    }
  };
}

export class CollectionRootKeysInput {
  _keys = [];
  orbitSensitivity = 0.01;
  scaleSensitivity = 0.001;
  root;
  _noPreventDefault = false;
  _isAttached = false;

  constructor(root) {
    this.root = root;
  }

  scaleRoot(val) {
    let currentScale = this.root.scaling.x;
    let newScale = val + currentScale;

    if (newScale < 0) {
      newScale = 0;
    }

    this.root.scaling = new Vector3(newScale, newScale, newScale);
  }

  onKeyDown = evt => {
    var index = this._keys.indexOf(evt.code);
    if (index === -1) {
      this._keys.push(evt.code);
    }
    if (!this._noPreventDefault) {
      evt.preventDefault();
    }
  };

  onKeyUp = evt => {
    var index = this._keys.indexOf(evt.code);
    if (index >= 0) {
      this._keys.splice(index, 1);
    }
    if (!this._noPreventDefault) {
      evt.preventDefault();
    }
  };

  //Required by input manager
  getTypeName = function() {
    return "CollectionRootKeysInput";
  };
  //Required by input manager
  getSimpleName = function() {
    return "keyboardRotate";
  };
  //Required by input manager
  attachControl = function(element, noPreventDefault) {
    if (!this._hasAttached) {
      this._noPreventDefault = noPreventDefault;
      element.tabIndex = 1;

      element.addEventListener("keydown", this.onKeyDown, false);
      element.addEventListener("keyup", this.onKeyUp, false);
      Tools.RegisterTopRootEvents(element, [
        { name: "blur", handler: this._onLostFocus }
      ]);

      this._isAttached = true;
    }
  };

  //Required by input manager
  detachControl = function(element) {
    if (this._isAttached) {
      element.removeEventListener("keydown", this.onKeyDown);
      element.removeEventListener("keyup", this.onKeyUp);
      Tools.UnregisterTopRootEvents(element, [
        { name: "blur", handler: this._onLostFocus }
      ]);
      this._keys = [];
      this.onKeyDown = null;
      this.onKeyUp = null;
      this._isAttached = false;
    }
  };

  checkInputs = function() {
    if (this._isAttached) {
      // Keyboard
      for (var index = 0; index < this._keys.length; index++) {
        var code = this._keys[index];
        switch (code) {
          case "ArrowLeft":
            this.root.rotate(Vector3.Up(), this.orbitSensitivity, Space.WORLD);
            break;
          case "ArrowRight":
            this.root.rotate(Vector3.Up(), -this.orbitSensitivity, Space.WORLD);
            break;
          case "ArrowUp":
            this.root.rotate(
              Vector3.Right(),
              this.orbitSensitivity,
              Space.WORLD
            );
            break;
          case "ArrowDown":
            this.root.rotate(
              Vector3.Right(),
              -this.orbitSensitivity,
              Space.WORLD
            );
            break;
          case "PageUp":
            this.scaleRoot(this.scaleSensitivity);
            break;
          case "PageDown":
            this.scaleRoot(-this.scaleSensitivity);
            break;
          case "Home":
            this.root.position = Vector3.Zero();
            this.root.rotationQuaternion = Quaternion.Identity();
            this.root.scaling = Vector3.One();
            break;
          default:
            break;
        }
      }
    }
  };
}
