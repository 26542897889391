import React from "react";
import FileDrop from "react-file-drop";
import { Header, Segment, Icon, Message } from "semantic-ui-react";
import "./ModelDrop.css";

class ModelDrop extends React.Component {
  state = {
    error: false,
    errorMessage: ""
  };

  handleDrop = (files, event) => {
    event.preventDefault();

    this.clearError();

    if (files.length !== 1) {
      this.setState({
        error: true,
        errorMessage: "You can only drop a single file"
      });
      return;
    }

    let file = files[0];

    if (!file.name.endsWith(".glb")) {
      this.setState({
        error: true,
        errorMessage: "File must be a GLB"
      });
      return;
    }

    const url = URL.createObjectURL(file);
    this.props.onModelDrop(url);
  };

  clearError = () => {
    this.setState({
      error: false,
      errorMessage: ""
    });
  };

  renderDropZone() {
    return (
      <div>
        <Segment placeholder>
          <Header icon>
            <Icon name="file outline" />
            Drop GLB file here..
          </Header>
          <FileDrop onDrop={this.handleDrop} className={"drop-box-container"} />
        </Segment>
      </div>
    );
  }

  renderError() {
    return (
      <Message negative onClick={this.clearError}>
        <Message.Header>Uh oh!</Message.Header>
        <p>{this.state.errorMessage}</p>
      </Message>
    );
  }

  render() {
    return (
      <>
        {this.state.error ? this.renderError() : null}
        {this.renderDropZone()}
      </>
    );
  }
}

export default ModelDrop;
