
const uploadFile = (data, cb) => ({
  type: 'UNIVERSAL_UPLOAD_FILE',
  data,
  cb,
});

export default {
  uploadFile
};
