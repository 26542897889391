import React from "react";
import { Step, Message, Header } from "semantic-ui-react";
import Meta from "./Meta";
import Data from "./Data";
import Model from "./Model";
import ModelDrop from "../../components/ModelDrop/ModelDrop";
import { v4 as uuidv4 } from 'uuid';

class NewCollection extends React.Component {
  state = {
    currentStep: 0,
    error: false,
    errorMessage: false,
    showSpinner: false,
    modelURL: ""
  };

  model = {
    id: "glb_sd_1",
    status: "published",
    lod: "sd",
    version: 1,
    format: "glb",
    filename: "todo",
    url: "todo",
    file: {}
  };

  data = {
    id: "todo",
    description: "todo",
    status: "published",
    default: true,
    labels: {},
    groups: {},
  };

  collection = {
    id: "todo",
    enabled: true,
    title: {},
    description: {},
    name: "New Collection",
    image: "",
    data: [this.data],
    models: {
      glb_sd_1: this.model
    },
    bundles: []
  };

  setError = message => {
    this.setState({ error: true, errorMessage: message });
  };

  renderContent() {
    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>Uh Oh!</Message.Header>
          <p>{this.state.errorMessage}</p>
        </Message>
      );
    } else if (this.state.showSpinner) {
      return <div>SPINNER</div>;
    } else {
      switch (this.state.currentStep) {
        case 0: //Model drop
          return (
            <ModelDrop
              onModelDrop={modelURL => {
                this.setState({ modelURL, currentStep: 1 });
              }}
            />
          );
        case 1: //Setup model
          return (
            <Model
              goNames
              errorMessage={this.setError}
              modelURL={this.state.modelURL}
              setModelURL={(modelURL, modelFile) => {
                this.model.filename = uuidv4() + ".glb";
                this.model.url = modelURL;
                this.model.file = new File([modelFile], this.model.filename) 
                this.setState({ modelURL, currentStep: 2 });
              }}
            />
          );

        case 2: //Setup data
          return (
            <Data
              errorMessage={this.setError}
              modelURL={this.state.modelURL}
              data={this.data}
              onNext={() => {
                this.setState({ currentStep: 3 });
              }}
            />
          );
        case 3: //Setup Meta
          return (
            <Meta
              errorMessage={this.setError}
              modelURL={this.state.modelURL}
              collection={this.collection}
              history={this.props.history}
            />
          );

        default:
          this.setError("Uncaught State");
          return <Header>Uncaught State</Header>;
      }
    }
  }

  render() {
    const steps = [
      {
        key: "import",
        title: "Import Model"
      },
      {
        key: "model",
        title: "Model"
      },
      {
        key: "data",
        title: "Data"
      },
      {
        key: "meta",
        title: "Meta"
      }
    ];

    steps.forEach((step, index) => {
      if (this.state.currentStep === index) {
        step.active = true;
      } else if (this.state.currentStep < index) {
        step.disabled = true;
      }
    });

    return (
      <>
        <Step.Group items={steps} />
        {this.renderContent()}
      </>
    );
  }
}

export default NewCollection;
