import React from "react";
import { Header, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import Actions from "../../actions";
import ModelList from "./EditModel_ModelList";
import history from "../../history";
import qs from "query-string";

class EditModel extends React.Component {
  state = {
    fetchingModelForDownload: null,
    error: false,
    errorMessage: ""
  };

  doDownloadModel = model => {
    if (model.url) {
      this.downloadURL(model, model.url);
    } else {
      this.setState({ isFetchingModelForDownload: model.filename });
      this.props.getCollectionModelURL(
        this.props.collection.id,
        model.id,
        model.filename,
        resp => {
          console.log(resp)
          if (resp.success) {
            this.setState({ isFetchingModelForDownload: null });
            this.downloadURL(model, model.url);
          } else {
            this.setState({
              isFetchingModelForDownload: null,
              error: true,
              errorMessage: resp.msg
            });
          }
        }
      );
    }
  };

  doEditModel = model => {
    history.push({
      pathname: `/collections/${this.props.collection.id}/model/${model.id}`
    });
  };

  doAddModel = (e, lod) => {
    const url = URL.createObjectURL(e.target.files[0]);

    //Figure out the next version
    let version = 0;
    const models = Object.values(this.props.collection.models);
    models.forEach(m => {
      if (m.format === "glb" && m.lod === lod && m.version > version) {
        version = m.version;
      } else {
      }
    });
    version++;

    const query = {
      url,
      file: e.target.files[0],
      lod,
      version
    };

    const searchString = qs.stringify(query);

    history.push({
      pathname: `/collections/${this.props.collection.id}/add`,
      search: searchString
    });
  };

  downloadURL(model, url) {
    console.log(model, url)
    const filename = `${this.props.collection.name}_${model.lod}_${model.version}.${model.format}`;
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    a.click();
  }

  renderGLBModels = () => {
    const glb_hd = [];
    const glb_sd = [];
    const glb_ld = [];

    const models = Object.values(this.props.collection.models);
    models.forEach(model => {
      if (model.format === "glb") {
        if (model.lod === "hd") {
          glb_hd.push(model);
        } else if (model.lod === "sd") {
          glb_sd.push(model);
        } else if (model.lod === "ld") {
          glb_ld.push(model);
        }
      }
    });

    glb_hd.sort((a, b) => (a.version > b.version ? 1 : -1));
    glb_sd.sort((a, b) => (a.version > b.version ? 1 : -1));
    glb_ld.sort((a, b) => (a.version > b.version ? 1 : -1));

    return (
      <Segment>
        <Header>GLB Models</Header>
        <ModelList
          modelList={glb_hd}
          lod="hd"
          clickDownloadModel={this.doDownloadModel}
          clickEditModel={this.doEditModel}
          clickAddModel={this.doAddModel}
          isFetchingModelForDownload={this.state.fetchingModelForDownload}
        />
        <br />
        <ModelList
          modelList={glb_sd}
          lod="sd"
          clickDownloadModel={this.doDownloadModel}
          clickEditModel={this.doEditModel}
          clickAddModel={this.doAddModel}
          isFetchingModelForDownload={this.state.fetchingModelForDownload}
        />
        <br />
        <ModelList
          modelList={glb_ld}
          lod="ld"
          clickDownloadModel={this.doDownloadModel}
          clickEditModel={this.doEditModel}
          clickAddModel={this.doAddModel}
          isFetchingModelForDownload={this.state.fetchingModelForDownload}
        />
      </Segment>
    );
  };

  render() {
    return <>{this.renderGLBModels()}</>;
  }
}

export default connect(null, {
  getCollectionModelURL: (collectionId, modelName, callback) =>
    Actions.Collections.getCollectionModelURL(collectionId, modelName, callback)
})(EditModel);
