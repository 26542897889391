import * as TYPES from './types'; 

export const setUserData = (user) => ({
  type: TYPES.SET_USER_DATA,
  payload: user
});

export const editUserData = (user, callback) => ({
  type: TYPES.EDIT_USER_DATA,
  payload: user,
  callback
});

export const logOut = () => ({
  type: TYPES.LOGOUT_USER
});

export const refreshUser = () => ({
  type: TYPES.REFRESH_USER
});

export const checkForUser = () => ({
  type: TYPES.CHECK_FOR_USER
});

export const userIsLoggingIn = () => ({
  type: TYPES.USER_IS_LOGGING_IN
});

export const userIsNotLoggedIn = () => ({
  type: TYPES.USER_IS_NOT_LOGGED_IN
});

export default {
  setUserData,
  logOut,
  checkForUser,
  refreshUser,
  editUserData,
  userIsLoggingIn,
  userIsNotLoggedIn
};