import * as TYPES from "../actions/types"

export const LoginEnum = Object.freeze({
  init: "init",
  isLoggingIn: "isLoggingIn",
  isLoggedIn: "isLoggedIn",
  isNotLoggedIn: "isNotLoggedIn"
});

const initialState = {
  id: null,
  name: null,
  family_name: null,
  email: null,
  phone: null,
  idToken: null,
  loginStatus: LoginEnum.init,
  groups: [],
  avatar: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
 
    case TYPES.USER_IS_LOGGING_IN:
      return { ...state, loginStatus: LoginEnum.isLoggingIn};

    case TYPES.USER_IS_NOT_LOGGED_IN:
      return { ...state, loginStatus: LoginEnum.isNotLoggedIn};

    case TYPES.SET_USER_DATA:
      return { ...action.payload, loginStatus: LoginEnum.isLoggedIn };

    case TYPES.LOGOUT_USER:
      return { ...initialState, userData: {}, loginStatus: LoginEnum.isNotLoggedIn};

    default:
      return state;
  }
};

export default reducer;
