import React from "react";
import { Segment, Label, Table, Button } from "semantic-ui-react";
import ModelListItem from "./EditModel_ModelListItem";
import { LODColor, LODString } from "../LOD/LOD";

const ModelList = props => {
  const {
    modelList,
    lod,
    clickDownloadModel,
    clickEditModel,
    clickAddModel,
    isFetchingModelForDownload
  } = props;

  let labelColor = LODColor(lod);

  const renderNoList = () => {
    return (
      <>
        <br />
        <br />
        <Label>This collection does not have any {LODString(lod)} models</Label>
        <br />
        <br />
      </>
    );
  };

  const renderTable = () => {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Version</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {modelList.map(m => {
            return (
              <ModelListItem
                key={m.filename}
                model={m}
                clickDownloadModel={clickDownloadModel}
                clickEditModel={clickEditModel}
                showDownloadSpinner={
                  isFetchingModelForDownload === m.filename ? true : false
                }
              />
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Segment color={labelColor}>
      <Label color={labelColor} basic>
        {LODString(lod)} Models
      </Label>
      {modelList.length === 0 ? renderNoList() : renderTable()}
      <Button
        icon="plus"
        content={`Add ${LODString(lod)} model`}
        labelPosition="left"
        color={labelColor}
        htmlFor={lod + "-file"}
        as="label"
      />
      <input
        type="file"
        id={lod + "-file"}
        style={{ display: "none" }}
        accept=".glb"
        onChange={(e)=>clickAddModel(e, lod)}
      />
    </Segment>
  );
};

export default ModelList;
