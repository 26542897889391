import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { CollectionRoot } from "./CollectionRoot";
import { Camera } from "./Camera";
import { Lighting } from "./Lighting";
import { LoadGLB } from "./LoadGLB";
import "@babylonjs/core/Materials/standardMaterial";
import "@babylonjs/core/Meshes/Builders/sphereBuilder";
import "@babylonjs/core/Meshes/Builders/boxBuilder";
import "@babylonjs/core/Meshes/Builders";
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Loading/loadingScreen";
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import CollectionObjects from "./CollectionObjects";
import { Tools } from "@babylonjs/core/Misc/tools";
import { ExportGLB } from "./ExportGLB";
import { LoadLocalGLBFile } from "./LoadLocalGLB";
import { Quaternion, Vector3 } from "@babylonjs/core/Maths/math";

export default class WebGLPlayer {
  canvas;
  scene;
  engine;
  collectionRoot;
  camera;
  lighting;
  collectionObjects;

  _doResize = false;
  _currentViewCanvas = null;

  constructor(canvas) {
    this.canvas = canvas;
    this.engine = new Engine(canvas);
    this.scene = new Scene(this.engine);
    this.camera = new Camera(this);
    this.lighting = new Lighting(this);
    this.collectionRoot = new CollectionRoot(this);

    this.engine.runRenderLoop(() => {
      this.scene.render();

      if (this._doResize) {
        this._doResize = false;
        this.engine.resize();
      }
    });

    this.camera.attachCollectionRootController(canvas);
  }

  dispose() {
    this.engine.dispose();
  }

  loadGLBData = (data, onSuccess, onFail) => {
    new LoadLocalGLBFile(
      data,
      this,
      newCollectionRoot => {
        this.collectionRoot.autoCenterOnCollection();
        this.collectionObjects = new CollectionObjects(this, newCollectionRoot);

        onSuccess();
      },
      failMessage => {
        console.log("FAIL " + failMessage);
        onFail(failMessage);
      }
    );
  };

  loadGLBFromURL = (url, onSuccess, onFail) => {
    let loader = new LoadGLB(
      this,
      newCollectionRoot => {
        this.collectionRoot.autoCenterOnCollection();
        this.collectionObjects = new CollectionObjects(this, newCollectionRoot);
        onSuccess();
      },
      failMessage => {
        console.log("FAIL " + failMessage);
        onFail(failMessage);
      }
    );

    loader.LoadFromURL(url);
  };

  focusOnObject = objectName => {
    //First get the bounds for this object
    const bounds = this.collectionObjects.GetObjectBounds(objectName);
    if (!bounds) {
      console.warn("Failed to get bounds for object " + objectName);
    } else {
      console.log(bounds);
    }
  };

  setShowInspector(show) {
    if (show) {
      this.scene.debugLayer.show();
      this._doResize = true;
    } else {
      this.scene.debugLayer.hide();
      this._doResize = true;
    }
  }

  takeScreenShot(callback) {
    Tools.CreateScreenshotUsingRenderTarget(
      this.engine,
      this.camera.cam,
      { width: 1024, height: 1024 },
      data => {
        callback(data);
      }
    );
  }

  getCurrentView(){
    const view = {
      root_position: this.collectionRoot.transform.position,
      root_rotation: this.collectionRoot.transform.rotationQuaternion,
      root_scale: this.collectionRoot.transform.scaling.x
    }    
    return view;
  }

  setCurrentView(currentView){    
    this.collectionRoot.transform.position = new Vector3(currentView.root_position.x, currentView.root_position.y, currentView.root_position.z);
    this.collectionRoot.transform.rotationQuaternion = new Quaternion(currentView.root_rotation.x, currentView.root_rotation.y, currentView.root_rotation.z, currentView.root_rotation.w);
    this.collectionRoot.transform.scaling = new Vector3(currentView.root_scale, currentView.root_scale, currentView.root_scale);
  }

  exportCollection() {
    ExportGLB(this, url => {
      console.log(url);
    });
  }
}
