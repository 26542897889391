import React from "react";
import { Table, Button, Input } from "semantic-ui-react";

const EditObjectLabelItem = ({
  goName,
  label,
  showingObject,
  onLabelChange,
  toggleShowObject
}) => {
  return (
    <Table.Row key={goName}>
      <Table.Cell>{goName}</Table.Cell>
      <Table.Cell>
        <Input
          value={label}
          placeholder="Label"
          onChange={(e, d) => onLabelChange(goName, d.value)}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          toggle
          active={showingObject === goName}
          icon="eye"
          onClick={() => toggleShowObject(goName)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EditObjectLabelItem;
