import React from "react";
import { Button, Icon, Menu } from "semantic-ui-react";
import PlayerCanvas from "../../components/PlayerCanvas/PlayerCanvas";
import WebGLPlayer from "../../player/WebGLPlayer";
import { ExportGLB } from "../../player/ExportGLB";

const playerCanvasID = "webGL_Canvas";

class Model extends React.Component {
  player = null;

  state = {
    isSaving: false
  }

  handleClickNext = () => {
    if(this.player)
    {
      this.setState({ isSaving: true });
      ExportGLB(this.player, exportResp=>{
        if (exportResp.success) {
          this.props.setModelURL(exportResp.modelURL, exportResp.file);
        } else
        {
          console.log("could not export")
          this.props.errorMessage("Could not export model");
        }
      })
    } else
    {
      this.props.errorMessage("Could not find player");
    }
  }

  componentDidMount() {
    console.log("Mounting Model")
    this.makePlayer();
  }

  componentWillUnmount() {
    this.player.dispose();
  }

  makePlayer() {
    console.log("MODEL make player")
    let playerCanvas = document.getElementById(playerCanvasID);
    if (playerCanvas) {
      this.player = new WebGLPlayer(playerCanvas);
      this.player.loadGLBFromURL(
        this.props.modelURL,
        () => {
          //Success Model
        },
        errorMsg => {
          //Fail
          this.props.errorMessage(errorMsg);
        }
      );
      this.player.setShowInspector(true);
    }
  }

  render() {
    return (
      <>
        <Menu secondary>
          <Menu.Item header>Setup Model</Menu.Item>
          <Menu.Menu position="right">
            <Button
              icon
              floated="right"
              labelPosition="right"
              onClick={this.handleClickNext}
              loading={this.state.isSaving}
            >
              Next
              <Icon name="right arrow" />
            </Button>
          </Menu.Menu>
        </Menu>

        <div style={{ height: "calc(100vh - 300px)" }}>
          <PlayerCanvas canvasId={playerCanvasID} />
        </div>
      </>
    );
  }
}

export default Model;
