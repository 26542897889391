import {
    call,
    select,
    put,
  } from 'redux-saga/effects';
  
  import Actions from '../actions';
  import UserController from '../utils/UserController';
  import {LoginEnum} from '../reducers/userReducer';
  
  export const checkForUser = () => function* checkForUserSaga(action) {
    try {    
      yield put(Actions.User.userIsLoggingIn());    
      const response = yield call(UserController.use.CheckForUser, null);
  
      if (response) {      
        yield put(Actions.User.setUserData(response));
      }

      const state = yield select();
      
      if(state.User.loginStatus !== LoginEnum.isLoggedIn){
        yield put(Actions.User.userIsNotLoggedIn());
      }
    } 
    catch (error) {
      const state = yield select();
      if(state.User.loginStatus !== LoginEnum.isLoggedIn){
        yield put(Actions.User.userIsNotLoggedIn());
      }
  
      throw new Error(error);
    }
  
  };
  
  export const refreshUser = () => function* refreshUserSaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in to Refresh User");
      }
  
      yield put({ type: 'FETCH_MODULE', process: action.process || true });
      const newIdToken = yield call(UserController.use.RefreshUser, null);
      
      const user = state.User
      user.idToken = newIdToken
      yield put(Actions.User.setUserData(user));
      
      yield put({ type: 'FETCH_REQUEST_END' });
    } catch (error) {
      console.error(error)
      yield put({ type: 'FETCH_REQUEST_END' });
    }
  };
  
  export const editUser = () => function* editUserSaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in to Edit User");
      }
      
      if (!action.payload) {
        throw new Error("action needs action.payload");
      }
  
      yield put({ type: 'FETCH_MODULE', process: action.process || true });
      const newUserInfo = yield call(UserController.use.EditUser, action.payload);
      yield put(Actions.User.setUserData(newUserInfo));
  
      if(action.callback) {
        action.callback(null)
      }
      
      yield put({ type: 'FETCH_REQUEST_END' });
    } catch (error) {
      console.error(error)
      if(action.callback) {
        yield call(action.callback, { success: false, msg: error.message });
      }
      yield put({ type: 'FETCH_REQUEST_END' });
    }
  };