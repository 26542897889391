import * as TYPES from "../actions/types";

const initialState = {
  list: []
};

const reducer = (state = initialState, action) => {
  if(action.type === TYPES.UPDATE_APP_IN_LIST) {
    let newState = []

    if(state.list.length > 0) {
      newState = state.list.map(app => {
        if(app.PK === action.app.PK) {
          return action.app
        }
        return app
      })
    } else {
      newState = [action.app]
    }
    
    return newState
  }
  else if(action.type === TYPES.APP_ADD_ALL_TO_LIST) {
    let newState = {list: action.allApps}
    return newState
  }
  return state
};

export default reducer;
