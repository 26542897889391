import { Vector3, Quaternion } from "@babylonjs/core/Maths/math";
import { FreeCamera } from "@babylonjs/core/Cameras/freeCamera";
import {
  CollectionInput_ClickToCenter,
  CollectionRootKeysInput,
  CollectionInput_RollZoom,
  CollectionInput_LeftClickToPan,
  CollectionInput_RightClickToOrbit
} from "./CollectionRoot";

export class Camera {
  cam;
  _player;

  constructor(player) {
    this._player = player;
    this.cam = new FreeCamera(
      "MainCamera",
      new Vector3(0, 0, -1),
      player.scene
    );
    this.cam.minZ = 0.0;
    this.cam.fov = (23.5 * Math.PI) / 180;
    this.resetCamera();
  }

  resetCamera() {
    this.cam.rotationQuaternion = Quaternion.Identity();
    this.cam.position = new Vector3(0, 0, -1);
  }

  attachCollectionRootController = (canvas) => {
    this.resetCamera();
    this.cam.attachControl(canvas, true);
    this.cam.inputs.removeByType("FreeCameraKeyboardMoveInput");
    this.cam.inputs.removeByType("FreeCameraMouseInput");

    this.cam.inputs.add(
      new CollectionRootKeysInput(this._player.collectionRoot.transform)
    );

    this.cam.inputs.add(
      new CollectionInput_RollZoom(this._player.scene, this._player.collectionRoot.transform)
    );

    this.cam.inputs.add(
      new CollectionInput_ClickToCenter(this._player.scene, this._player.collectionRoot.transform)
    );

    this.cam.inputs.add(
      new CollectionInput_LeftClickToPan(this._player.scene, this._player.collectionRoot.transform)
    );

    this.cam.inputs.add(
      new CollectionInput_RightClickToOrbit(this._player.scene, this._player.collectionRoot.transform)
    );
  }
}
