import React from "react";
import { connect } from "react-redux";
import Actions from "../actions";
import { Message, Icon, Label, Button, Menu, Segment } from "semantic-ui-react";
import LoginState from "../components/LoginState/LoginState";
import PlayerCanvas from "../components/PlayerCanvas/PlayerCanvas";
import WebGLPlayer from "../player/WebGLPlayer";
import { ExportGLB } from "../player/ExportGLB";
import { Link } from "react-router-dom";
import { LODLabel } from "../components/LOD/LOD";
import StatusDropdown from "../components/Status/StatusDropdown";

const playerCanvasID = "webGL_Canvas";

class Collection_EditModel extends React.Component {
  player = null;

  state = {
    isFetching: true,
    error: false,
    errorMessage: "",
    model: this.props.model,
    showSaveSuccess: false,
    isSaving: false
  };

  componentDidMount() {
    if (!this.props.collection) {
      this.getCollections();
    } else {
      this.getModelURL();
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  getCollections() {
    this.setState({ isFetching: true });
    this.props.fetchAllCollections(resp => {
      if (resp.success) {
        this.getModelURL();
      } else {
        this.setState({
          isFetching: false,
          error: true,
          errorMessage: resp.msg
        });
      }
    });
  }

  getModelURL() {
    if (!this.props.model) {
      this.setState({
        isFetching: false,
        error: true,
        errorMessage: "Could not find model for collection"
      });
    } else if (!this.props.model.url) {
      this.props.getCollectionModelURL(
        this.props.collection.id,
        this.props.model.id,
        this.props.model.filename,
        resp => {
          if (resp.success) {
            this.setState({
              isFetching: false,
              status: this.props.model.status
            });
          } else {
            this.setState({
              isFetching: false,
              error: true,
              errorMessage: resp.msg
            });
          }
        }
      );
    } else {
      this.setState({ isFetching: false, status: this.props.model.status });
    }
  }

  componentDidUpdate() {
    if (!this.player && !this.state.isFetching && !this.state.error) {
      this.makePlayer();
    }
  }

  makePlayer() {
    let playerCanvas = document.getElementById(playerCanvasID);
    if (playerCanvas) {
      this.player = new WebGLPlayer(playerCanvas);
      this.player.loadGLBFromURL(
        this.props.model.url,
        () => {
          //Success
          this.setState({ isFetching: false });
        },
        errorMsg => {
          //Fail
          this.setState({
            isFetching: false,
            error: true,
            errorMessage: errorMsg
          });
        }
      );
      this.player.setShowInspector(true);
    }
  }

  doSave = () => {
    if (this.player) {
      this.setState({ isSaving: true });
      ExportGLB(this.player, async(exportResp) => {
        if (exportResp.success) {
          let file = new File([exportResp.file], this.props.model.filename) 
          this.props.uploadModel(this.props.model.filename, file, (result) => {
            if(result.success) {
              
              this.props.updateCollectionModel(
                this.props.collection_id,
                this.props.model_id,
                this.state.model,
                updateResp => {
                  this.setState({ isSaving: false });
                }
              );
            } else {
              this.setState({
                error: true,
                errorMessage: result.msg
              });
            }
          })
        } else {
          this.setState({
            error: true,
            errorMessage: exportResp.message
          });
        }
      });
    }
  };

  onStatusDropdownChange = (e, d) => {
    let model = this.state.model
    model.status = d.value
    this.setState({ model });
  };

  renderContent = () => {
    return (
      <div>
        <Menu attached="top">
          <Menu.Item>
            <Button as={Link} to={`/collections/${this.props.collection_id}?tab=Models`}>
              <Icon name="left arrow" />
              Back to Collection
            </Button>
          </Menu.Item>

          <Menu.Item>
            <StatusDropdown
              status={this.state.model.status}
              onDropdownChange={this.onStatusDropdownChange}
            />
            <LODLabel lod={this.state.model.lod} />
            <Label>Version: {this.state.model.version}</Label>
          </Menu.Item>
          

          <Menu.Item position="right">
            <Button
              positive
              onClick={this.doSave}
              loading={this.state.isSaving}
            >
              Save Changes
            </Button>
            {this.state.showSaveSuccess ? (
              <Label basic color="green" pointing="left">
                Saved!
              </Label>
            ) : null}
          </Menu.Item>
        </Menu>

        <Segment attached="bottom">
          <div style={{ height: "calc(100vh - 200px)" }}>
            <PlayerCanvas canvasId={playerCanvasID} />
          </div>
        </Segment>
      </div>
    );
  };

  render() {
    if (this.props.loginStatus === "isLoggedIn") {
      if (this.state.error) {
        return (
          <Message negative>
            <Message.Header>Uh oh...</Message.Header>
            <p>{this.state.errorMessage}</p>
          </Message>
        );
      } else if (this.state.isFetching) {
        return (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Just one second</Message.Header>
              We are fetching that collections for you...
            </Message.Content>
          </Message>
        );
      } else {
        return this.renderContent();
      }
    } else {
      return <LoginState loginStatus={this.props.loginStatus} />;
    }
  }
}

const MapStateToProps = (state, passedProps) => {
  const loginStatus = state.User.loginStatus;
  const collection_id = passedProps.match.params.collection_id;
  const model_id = passedProps.match.params.model_id;

  let collection = null;
  let model = null;
  if (state.collections.list.hasOwnProperty(collection_id)) {
    collection = state.collections.list[collection_id];

    if (collection.models.hasOwnProperty(model_id)) {
      model = collection.models[model_id];
    }
  }

  return {
    collection_id,
    model_id,
    loginStatus,
    collection,
    model
  };
};

export default connect(MapStateToProps, {
  getCollectionModelURL: (collectionId, modelId, filename, callback) =>
    Actions.Collections.getCollectionModelURL(
      collectionId,
      modelId,
      filename,
      callback
    ),
  fetchAllCollections: callback =>
    Actions.Collections.fetchAllCollections(callback),
  updateCollectionModel: (
    collection_id,
    model_id,
    filename,
    blobURL,
    callback
  ) =>
    Actions.Collections.updateCollectionModel(
      collection_id,
      model_id,
      filename,
      blobURL,
      callback
    ),
  uploadModel: (file, modelData, cb) => Actions.Model.getModelPostUrl(file, modelData, cb)
})(Collection_EditModel);
