import Actions from "../actions/collections";
import { call, put, select } from "redux-saga/effects";

export const getCollectionBundle = requestModule =>
  function* getCollectionBundleSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      if (!action.payload.hasOwnProperty("bundleId")) {
        throw new Error("bundleId is required");
      }

      if (!action.payload.hasOwnProperty("offset")) {
        throw new Error("offset is required");
      }

      if (!action.payload.hasOwnProperty("limit")) {
        throw new Error("limit is required");
      }

      const { bundleId, offset, limit } = action.payload;

      const req = yield call(requestModule, {
        path: `bundles/${bundleId}/all`,
        query: { offset, limit }
      });

      const total = req.data.totalCollections;
      let totalRetrieved = offset + limit;
      let allHaveBeenRetrieved = false;
      if (totalRetrieved >= total) {
        totalRetrieved = total;
        allHaveBeenRetrieved = true;
      }
      yield put(
        Actions.setCollectionsRetrieved(
          total,
          totalRetrieved,
          allHaveBeenRetrieved
        )
      );

      const metaArray = req.data.collectionsMetas;
      const collectionArray = req.data.collections;
      const collections = {};

      collectionArray.forEach(collection => {
        collections[collection.collection_id] = {
          id: collection.collection_id,
          image: collection.thumbnail,
          name: collection.collection_name
        };
      });

      metaArray.forEach(collectionMeta => {
        const id = collectionMeta.collection_bundle_id; //NOTE! This needs to change. Attribute is improperly named
        if (collections.hasOwnProperty(id)) {
          collections[id] = {
            ...collections[id],
            title: collectionMeta.title,
            description: collectionMeta.description
          };
        }
      });

      yield put(Actions.setCollections(Object.values(collections)));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const getAllCollections = requestModule =>
  function* getAllCollectionsSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `collection`
      });

      const total = req.data.total_count;
      let allHaveBeenRetrieved = true;
      yield put(
        Actions.setCollectionsRetrieved(total, total, allHaveBeenRetrieved)
      );

      let collections = req.data.collections.map(col => {
        const {
          enabled,
          title,
          description,
          name,
          image,
          legacyname,
          initialView
        } = col;
        return {
          id: col.PK,
          enabled,
          title,
          description,
          name,
          image,
          legacyname,
          initialView,
          data: [],
          models: {},
          bundles: []
        }
      })
      collections = collections.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0;  // names must be equal
      });

      yield put(Actions.setCollectionList(collections));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const getACollection = requestModule =>
  function* getACollectionSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `collection/${action.id}/allinfo`
      });
      const { PK, enabled, title, description, name, legacyname, initialView, image } = req.data.collection;
      let collection = {
        id: PK,
        enabled,
        title,
        description,
        name,
        legacyname,
        initialView,
        image,
        data: [],
        models: {},
        bundles: req.data.bundles
      };

      //Now attach the data to the collections
      collection.data = req.data.data.map(dataObj => {
        let labels = {}, groups = {}
        dataObj.objectData.forEach(objectData => {
          const { defaultLabel, goName, tags } = objectData;
          labels[goName] = defaultLabel;
          
          if(tags) {
            tags.forEach(tag => {
              if (groups.hasOwnProperty(tag)) {
                groups[tag].push(goName);
              } else {
                groups[tag] = [goName];
              }
            });
          }
        });

        return {
          id: dataObj.SK,
          description: dataObj.description,
          status: dataObj.status,
          default: dataObj.default,
          labels,
          groups
        }
      });

      //Now attach models
      req.data.models.forEach(modelData => {
        collection.models[`${modelData.format}_${modelData.lod}_${modelData.version}`] = {
          id: `${modelData.format}_${modelData.lod}_${modelData.version}`,
          status: modelData.status,
          lod: modelData.lod,
          version: modelData.version,
          format: modelData.format,
          filename: modelData.filename
        }
      });

      yield put(Actions.setAllInfoForCollection(collection));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const getACollectionsData = requestModule =>
  function* getACollectionsDataSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `collection/${action.collection_id}/data/${action.data_id}`
      });
      yield put(
        Actions.setACollectionsLabelsAndGroups(
          action.collection_id,
          action.data_id,
          req.data.data.objectData
        )
      );

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const patchCollectionMeta = requestModule =>
  function* patchCollectionMetaSaga(action) {
    try {
      const { id, meta } = action.payload;

      const req = yield call(requestModule, {
        path: `collection/${id}`,
        method: "PATCH",
        data: meta
      });

      let collection = req.data;
      const updatedMeta = {
        name: collection.name,
        legacyname: collection.legacyname,
        initialView: collection.initialView,
        image: collection.image,
        title: { ...collection.title },
        description: { ...collection.description }
      };

      //This reducer expects to have all the meta.
      //It is a put, not a patch so missing meta will be removed from the store
      yield put(Actions.setCollectionMeta(id, updatedMeta));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const patchCollectionData = requestModule =>
  function* patchCollectionDataSaga(action) {
    try {
      const { collection_id, data_id, data } = action.payload;
      const state = yield select(), 
      collection = state.collections.list[collection_id],
      collectionData = collection.data.filter(data => data.id === data_id)[0]
      let newData = {}, objectData = null

      if(data.description) {
        newData.description = data.description
      }

      if(data.status) {
        newData.status = data.status
      }

      if(data.hasOwnProperty("default")) {
        newData.default = data.default
      }

      //Update type collection-data
      if(Object.keys(newData).length > 0) {
        yield call(requestModule, {
          path: `collection/${collection_id}/data/${data_id}/reference`,
          method: "PATCH",
          data: {
            ...newData
          }
        });

        const updatedData = {
          ...collectionData,
          ...newData
        };

        yield put(Actions.setCollectionData(collection_id, data_id, updatedData));
      }

      //if there are new labels
      if(data.labels) {
        if(!data.groups && collectionData.groups) {
          objectData = Object.keys(data.labels).map((label) => {

            let groupsLabelIsIn = []
            Object.keys(collectionData.groups).forEach(group => {
              let groupContents = collectionData.groups[group],

              isMatch = false
              groupContents.forEach(content => {
                if(content === label) {
                  isMatch = true
                }
              })

              if(isMatch) {
                groupsLabelIsIn.push(group)
              }
            })

            return {
              goName: label,
              defaultLabel: data.labels[label],
              tags: groupsLabelIsIn
            };
          });
        } else {
          objectData = Object.keys(data.labels).map((item) => {
            return {
              goName: item,
              defaultLabel: data.labels[item]
            };
          });
        }
      }

      //if there are new groups
      if(data.groups) {
        if(!objectData) {
          objectData = Object.keys(collectionData.labels).map((item) => {
            return {
              goName: item,
              defaultLabel: collectionData.labels[item]
            };
          });
        }

        objectData = objectData.map((item) => {

          let groupsLabelIsIn = []
          Object.keys(data.groups).forEach(group => {
            let groupContents = data.groups[group],

            isMatch = false
            groupContents.forEach(content => {
              if(content === item.goName) {
                isMatch = true
              }
            })

            if(isMatch) {
              groupsLabelIsIn.push(group)
            }
          })
          return {
            ...item,
            tags: groupsLabelIsIn
          };
        });
      }
      
      //Update type data first
      if(objectData) {
        yield call(requestModule, {
          path: `collection/${collection_id}/data/${data_id}`,
          method: "PATCH",
          data: {
            data: { objectData }
          }
        });

        yield put(Actions.setACollectionsLabelsAndGroups(collection_id, data_id, objectData))
      }

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const getCollectionModelURL = requestModule =>
  function* getCollectionModelURLSaga(action) {
    try {
      const { collectionId, modelId, filename } = action;

      const req = yield call(requestModule, {
        path: `S3/PreSign/ModelVariants/${filename}/getObject/${filename}`
      });

      const dl = yield call(fetch, req.data);

      const blob = yield dl.blob();
      const blobURL = URL.createObjectURL(blob);

      yield put(Actions.setCollectionModelURL(collectionId, modelId, blobURL));
      if (action.callback) {
        yield call(action.callback, { success: true, url: blobURL });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const updateCollectionModel = requestModule =>
  function* updateCollectionModelSaga(action) {
    try {
      const { collection_id, model_id, model } = action;
      
      let req = yield call(requestModule, {
        path: `collection/${collection_id}/model/${model_id}`,
        method: "PATCH",
        data: {
          status: model.status
        }
      });

      yield put(Actions.addModelToCollection(collection_id, model_id, req.data));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const putCollectionModel = requestModule =>
  function* putCollectionModelSaga(action) {
    try {
      const { collection_id, lod, format, filename, status, version } = action;
      const newModel = {
        id: `${format}_${lod}_${version}`,
        format,
        version,
        status,
        filename,
        lod,
      };

      yield call(requestModule, {
        path: `collection/${collection_id}/model`,
        method: "POST",
        data: { lod, format, filename, status, version: Number(version) }
      });

      yield put(Actions.addModelToCollection(collection_id, newModel.id, newModel));

      if (action.callback) {
        yield call(action.callback, { success: true, model: newModel });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const putCollection = requestModule =>
function* putCollectionSaga(action) {
  let colReq = null

  try {
    let { collection } = action,
    { name, title, description, image, enabled, data, models, initialView } = collection

    //CREATE COLLECTION
    colReq = yield call(requestModule, {
      path: `collection`,
      method: "POST",
      data: { name, title, description, image, enabled, initialView }
    });

    //CREATE DATA
    for (let index = 0; index < data.length; index++) {
      let d = data[index]

      let dataObj = []
      Object.keys(d.labels).forEach(labelKey => {// set up labels
        let label = d.labels[labelKey]
        dataObj[labelKey] = {
          goName: labelKey,
          defaultLabel: label,
          tags:[]
        }
      })

      Object.keys(d.groups).forEach(groupKey => {// set up groups
        let groupObjs = d.groups[groupKey]
        groupObjs.forEach(obj => {
          dataObj[obj].tags = [ ...dataObj[obj].tags, groupKey ]
        })
      })
      
      let objectData = []
      Object.keys(dataObj).forEach(key => {// make it an array
        let data = dataObj[key]
        objectData.push(data)
      })

      yield call(requestModule, {
        path: `collection/${colReq.data.PK}/data`,
        method: "POST",
        data: { 
          description: d.description,
          status: d.status,
          default: d.default,
          data: { objectData },
          language: "en"
         }
      });
    }

    //CREATE MODELS
    for (let index = 0; index < Object.keys(models).length; index++) {
      let modelKey = Object.keys(models)[index],
          model = models[modelKey]

      yield call(requestModule, {
        path: `collection/${colReq.data.PK}/model`,
        method: "POST",
        data: { 
          version: model.version,
          lod: model.lod,
          filename: model.filename,
          format: model.format,
          status: model.status,
         }
      });
    }

    collection.id = colReq.data.PK
    collection.image = colReq.data.image
    
    yield put(
      Actions.addCollection(collection)
    );

    if (action.callback) {
      yield call(action.callback, { success: true});
    }
  } catch (err) {
    if (action.callback) {

      if(colReq) {
        yield call(requestModule, {
          path: `collection/${colReq.data.PK}`,
          method: "DELETE"
        });
      }

      yield call(action.callback, { success: false, msg: err.message });
    } else {
      throw err;
    }
  }
};