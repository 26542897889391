import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { Vector3, Color3, Color4 } from "@babylonjs/core/Maths/math";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";

export class Lighting {
  constructor(player) {
    let dirLight = new DirectionalLight(
      "_mainLight",
      new Vector3(-1, -1, 1),
      player.scene
    );
    dirLight.intensity = 3.0;
    dirLight.diffuse = new Color3(1.0, 1.0, 1.0);
    dirLight.parent = player.camera.cam;

    player.scene.environmentTexture = new CubeTexture(
      "https://dev.vivedlearning.com/WebGL_Environments/studio.env",
      player.scene,
      null,
      null,
      null,
      null,
      msg => {
        console.warn("Cube Texture fail: ", msg);
      }
    );

    player.scene.imageProcessingConfiguration.exposure = 1;
    player.scene.imageProcessingConfiguration.contrast = 1.8;

    player.scene.clearColor = new Color4(0.1, 0.1, 0.1, 0);
  }
}
