import React from "react";
import { connect } from "react-redux";
import Actions from "../../actions";
import {
  Divider,
  Item,
  Label,
  Message,
  Icon,
  Table, 
  Dropdown,
  Button
} from "semantic-ui-react";
import {Languages} from '../../utils/Languages';
// import { ActionRow } from "aws-amplify-react";

class EditOverview extends React.Component {

  constructor(props) {
    super(props);
    let selectedBundleIds = this.props.collection.bundles.map(bundle => bundle.PK)

    this.state = {
      originalSelectedBundles: selectedBundleIds,
      selectedBundles: selectedBundleIds,
      newlySelectedBundles: [],
      newlyRemovedBundles: [],
      bundles: null,
      isSaveBundleBtnVisible: false,
      collection: this.props.collection,
      error: false,
      errorMessage: "",
      showSaveSuccess: false,
      reset: false
    }
  }
  
  componentDidMount() {
    if(this.props.bundles) {
      this.setState({
        bundles: this.props.bundles.map(bundle => {
          return { key: bundle.id, text: bundle.description, value: bundle.id }
        })
      })
    } else {
      this.props.getBundles((result) => {
        if(result.success) {
          this.setState({
            bundles: this.props.bundles.map(bundle => {
              return { key: bundle.id, text: bundle.description, value: bundle.id }
            })
          })
        }
      })
    }
  }

  componentDidUpdate(props) {
    if(this.state.reset) {
      let selectedBundleIds = props.collection.bundles.map(bundle => bundle.PK)
      this.setState({
        originalSelectedBundles: selectedBundleIds,
        selectedBundles: selectedBundleIds,
        newlySelectedBundles: [],
        newlyRemovedBundles: [],
        reset: false,
        isLoading: false, 
        showSaveSuccess: true, 
        isSaveBundleBtnVisible: false, 
      })
    }
  }

  renderTitles = () => {
    let languages = [];
    if (this.props.collection.title) {
      languages = Object.keys(this.props.collection.title);
    }
    if (languages.length > 0) {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Language</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {languages.map(lang => {
              return (
                <Table.Row key={lang}>
                  <Table.Cell>{Languages.GetNameFromCode(lang)}</Table.Cell>
                  <Table.Cell>{this.props.collection.title[lang]}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Message warning>
          <Icon name="warning sign" />
          This Collection has no Titles
        </Message>
      );
    }
  };

  renderDescriptions = () => {
    let languages = [];
    if (this.props.collection.description) {
      languages = Object.keys(this.props.collection.description);
    }
    if (languages.length > 0) {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Language</Table.HeaderCell>
              <Table.HeaderCell>Descriptions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {languages.map(lang => {
              return (
                <Table.Row key={lang}>
                  <Table.Cell>{Languages.GetNameFromCode(lang)}</Table.Cell>
                  <Table.Cell>{this.props.collection.description[lang]}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Message warning>
          <Icon name="warning sign" />
          This Collection has no Descriptions
        </Message>
      );
    }
  };

  renderName = () => {
    if (this.props.collection.name) {
      return <Item.Header>{this.props.collection.name}</Item.Header>;
    } else {
      return (
        <Message warning>
          <Icon name="warning sign" />
          This Collection has no Name
        </Message>
      );
    }
  };

  renderDatas = () => {
    if (!this.props.collection.data || this.props.collection.data.length === 0) {
      return (
        <Message warning>
          <Icon name="warning sign" />
          This Collection has no Data
        </Message>
      );
    } else {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Default</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.props.collection.data.map(d => {
              return (
                <Table.Row key={d.id} positive={d.default}>
                  <Table.Cell>{d.description}</Table.Cell>
                  <Table.Cell>{this.statusToLabel(d.status)}</Table.Cell>
                  <Table.Cell>
                    {d.default ? <Icon name={"check"} /> : null}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      );
    }
  };

  render_Error() {
    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>Uh Oh!</Message.Header>
          <p>{this.state.errorMessage}</p>
        </Message>
      );
    } else {
      return null;
    }
  }

  statusToLabel = status => {
    if (status === "published") {
      return (
        <Label color="pink" horizontal>
          PUBLISHED
        </Label>
      );
    } else if (status === "review") {
      return (
        <Label color="yellow" horizontal>
          REVIEW
        </Label>
      );
    } else if (status === "draft") {
      return (
        <Label color="purple" horizontal>
          DRAFT
        </Label>
      );
    } else {
      return (
        <Label color="grey" horizontal>
          UNKNOWN
        </Label>
      );
    }
  };

  lodToLabel = status => {
    if (status === "hd") {
      return (
        <Label color="green" basic>
          HD
        </Label>
      );
    } else if (status === "sd") {
      return (
        <Label color="olive" basic>
          SD
        </Label>
      );
    } else if (status === "ld") {
      return (
        <Label color="orange" basic>
          LD
        </Label>
      );
    } else {
      return (
        <Label color="grey" basic>
          UNKNOWN
        </Label>
      );
    }
  };

  renderModels = () => {
    if (!this.props.collection.models || Object.keys(this.props.collection.models).length === 0) {
      return (
        <Message warning>
          <Icon name="warning sign" />
          This Collection has no Models
        </Message>
      );
    } else {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Format</Table.HeaderCell>
              <Table.HeaderCell>LOD</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Version</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Object.values(this.props.collection.models).map(m => {
              return (
                <Table.Row key={m.id}>
                  <Table.Cell>{m.format}</Table.Cell>
                  <Table.Cell>{this.lodToLabel(m.lod)}</Table.Cell>
                  <Table.Cell>{this.statusToLabel(m.status)}</Table.Cell>
                  <Table.Cell>{m.version}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      );
    }
  };

  editSelectedBundles(selectedBundles) {

    const sort = function(a, b){
      return a-b
    }
    let selectedBundlesInState = this.state.selectedBundles.sort(sort),
    originalSelectedBundlesInState = this.state.originalSelectedBundles.sort(sort)
    selectedBundles = selectedBundles.sort(sort)

    let isSaveBundleBtnVisible = false
    if(originalSelectedBundlesInState.length > 0 && selectedBundles.length === 0) {
      isSaveBundleBtnVisible = true
    }

    if(originalSelectedBundlesInState.length === 0 && selectedBundles.length > 0) {
      isSaveBundleBtnVisible = true
    }

    if(originalSelectedBundlesInState.length > 0 && selectedBundles.length > 0) {
      originalSelectedBundlesInState.forEach(originBundle => {
        selectedBundles.forEach(sb => {
          if(sb !== originBundle) {
            isSaveBundleBtnVisible = true
          }
        })
      })
    }

    if(selectedBundles.length > selectedBundlesInState.length) { // Some thing has been added
      let theAddedBundle = ""
      selectedBundles.forEach(sb => {
        let hasMatch = false

        selectedBundlesInState.forEach(sbinstate => {
          if(sb !== sbinstate) {
            hasMatch = true
            theAddedBundle = sb
          }
        })

        if(!hasMatch) {
          theAddedBundle = sb
        }
      })

      let newlyRemovedBundles = []
      this.state.newlyRemovedBundles.forEach(bundle => {// make sure to remove newly added bundles from removed bundles
        if(bundle !== theAddedBundle) {
          newlyRemovedBundles.push(bundle)
        }
      })

      this.setState({
        selectedBundles,
        newlySelectedBundles: [ ...this.state.newlySelectedBundles, theAddedBundle ],
        isSaveBundleBtnVisible,
        newlyRemovedBundles
      })
    }

    else if(selectedBundles.length < selectedBundlesInState.length) { // Some thing has been removed

      let theRemovedBundle = ""
      selectedBundlesInState.forEach(sbinstate => {
        let hasMatch = false

        selectedBundles.forEach(sb => {
          if(sb === sbinstate) {
            hasMatch = true
          }
        })
        
        if(!hasMatch) {
          theRemovedBundle = sbinstate
        }
      })

      let newlyRemovedBundles = this.state.newlyRemovedBundles
      originalSelectedBundlesInState.forEach(originBundle => {
        if(originBundle === theRemovedBundle) {
          newlyRemovedBundles = [ ...this.state.newlyRemovedBundles, theRemovedBundle ]
        }
      })

      let newlySelectedBundles = []
      this.state.newlySelectedBundles.forEach(bundle => {// make sure to remove newly remove bundles from added bundles
        if(bundle !== theRemovedBundle) {
          newlySelectedBundles.push(bundle)
        }
      })

      this.setState({
        selectedBundles,
        newlyRemovedBundles,
        isSaveBundleBtnVisible,
        newlySelectedBundles
      })
    }
  }

  saveSelectedBundles() {

    if(this.state.newlyRemovedBundles.length > 0 && this.state.newlySelectedBundles.length > 0) { // If we need to add and remove

      this.setState({isLoading: true})

      this.props.removeCollectionToBundles(this.state.collection.id, this.state.newlyRemovedBundles, (result) => {// remove
        if (result.success) {

          this.props.addCollectionToBundles(this.state.collection.id, this.state.newlySelectedBundles, (result) => { // add

            if (result.success) { 
              this.setState({ reset: true });
            } else {
              this.setState({ isLoading: false, error: true, errorMessage: result.msg });
            }
          })
        } else {
          this.setState({ isLoading: false, error: true, errorMessage: result.msg });
        }
      })
    }

    else if(this.state.newlySelectedBundles.length > 0) { // if we only need to add

      this.setState({isLoading: true})
      this.props.addCollectionToBundles(this.state.collection.id, this.state.newlySelectedBundles, (result) => {

        if (result.success) {
          this.setState({ reset: true });
        } else {
          this.setState({ isLoading: false, error: true, errorMessage: result.msg });
        }
      })
    }

    else if(this.state.newlyRemovedBundles.length > 0) { // if we only need to remove

      this.setState({isLoading: true})
      this.props.removeCollectionToBundles(this.state.collection.id, this.state.newlyRemovedBundles, (result) => {

        if (result.success) {
          this.setState({ reset: true });
        } else {
          this.setState({ isLoading: false, error: true, errorMessage: result.msg });
        }
      })
    }
  }

  renderBundles = () => {
    return (
      <Table celled>
        {this.state.isSaveBundleBtnVisible ? 
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Button
                  positive
                  name="save_changes"
                  onClick={() => this.saveSelectedBundles()}
                  loading={(this.state.isLoading)}
                >
                  Save Changes
                </Button>
                <Button
                  negative
                  name="discard_changes"
                  onClick={() => this.setState({ reset: true })}
                >
                  Dischard Changes
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header> :
          null
        }

        <Table.Body>
          <Dropdown placeholder='Select a bundle' value={this.state.selectedBundles} fluid multiple selection 
          options={this.state.bundles} 
          onChange={(e, data) => {
            this.editSelectedBundles(data.value)
          }}/>
        </Table.Body>
      </Table>
    )
  };

  render() {
    return (
      <>
        {this.state.error ? 
          <Message negative>
            <Message.Header>Uh Oh!</Message.Header>
            <p>{this.state.errorMessage}</p>
          </Message>
        :
        <>
          <Item.Group>
            <Item>
              <Item.Image
                size="small"
                src={this.props.collection.image ? this.props.collection.image : "https://react.semantic-ui.com/images/wireframe/image.png"}
              />
              <Item.Content>
                {this.renderName()}
                <Item.Meta>ID: {this.props.collection.id}</Item.Meta>
                <Item.Meta>Legacy name: {this.props.collection.legacyname}</Item.Meta>
              </Item.Content>
            </Item>
          </Item.Group>
    
          <Divider horizontal>Titles</Divider>
          {this.renderTitles()}
    
          <Divider horizontal>Descriptions</Divider>
          {this.renderDescriptions()}
    
          <Divider horizontal>Datas</Divider>
          {this.renderDatas()}
    
          <Divider horizontal>Models</Divider>
          {this.renderModels()}
    
          <Divider horizontal>Bundles</Divider>
          {this.renderBundles()}
        </>
        }
      </>
    );
  }
};

const MapStateToProps = (state, ownProps) => {
  return {
    bundles: state.Bundles.list ? state.Bundles.list : null,
    collection: ownProps.collection
  };
};

export default connect(MapStateToProps, {
  addCollectionToBundles: (col_id, selectedBundles, callback) => { return Actions.Bundles.addCollectionToBundle(col_id, selectedBundles, callback) },
  removeCollectionToBundles: (col_id, selectedBundles, callback) => { return Actions.Bundles.removeCollectionToBundle(col_id, selectedBundles, callback) },
  getBundles: (callback) => { return Actions.Bundles.getAllBundles(callback) }
})(EditOverview);
