import * as TYPES from "../actions/types";

const initialState = {
  list: {},
  totalCollectionCount: 0,
  collectionsRetrieved: 0,
  allHaveBeenRetrieved: false
};

const reducer = (state = initialState, action) => {
  let newCollectionList = {};
  switch (action.type) {
    case TYPES.COLLECTIONS_SET_COLLECTION_LIST:
      action.collections.forEach(collection => {
        newCollectionList[collection.id] = {
          ...collection
        };
      });

      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTIONS_SET_RETRIEVED:
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          "COLLECTIONS_SET_RETRIEVED action needs action.payload"
        );
      }

      if (!action.payload.total && action.payload.total !== 0) {
        throw new Error("total cannot be null or empty");
      }

      if (!action.payload.offset && action.payload.offset !== 0) {
        throw new Error("offset cannot be null or empty");
      }

      if (
        action.payload.allHaveBeenRetrieved === null ||
        action.payload.allHaveBeenRetrieved === undefined
      ) {
        throw new Error("allHaveBeenRetrieved cannot be null or undefined");
      }

      return {
        ...state,
        totalCollectionCount: action.payload.total,
        collectionsRetrieved: action.payload.offset,
        allHaveBeenRetrieved: action.payload.allHaveBeenRetrieved
      };

    case TYPES.COLLECTIONS_SET_ALL_INFO:
      newCollectionList = { ...state.list };
      newCollectionList[action.collection.id] = action.collection
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTIONS_SET_COLLECTION_META:
      newCollectionList = { ...state.list };
      newCollectionList[action.payload.id] = {
        ...state.list[action.payload.id],
        name: action.payload.meta.name,
        legacyname: action.payload.meta.legacyname,
        initialView: action.payload.meta.initialView,
        title: { ...action.payload.meta.title },
        description: { ...action.payload.meta.description },
        image: action.payload.meta.image
      };
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTIONS_SET_COLLECTION_DATA:
      const datasToSet = state.list[action.payload.collection_id].data.map(
        data => {
          return data.id === action.payload.data_id
            ? action.payload.data
            : data;
        }
      );
      newCollectionList = { ...state.list };
      newCollectionList[action.payload.collection_id].data = datasToSet;
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTIONS_SET_MODEL_URL:
      newCollectionList = { ...state.list };
      newCollectionList[action.collectionId].models[action.modelName].url =
        action.URL;
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTIONS_SET_OBJECT_LIST:
      newCollectionList = { ...state.list };
      newCollectionList[action.collection_id].objectList = [
        ...action.object_list
      ];
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTION_ADD_TO_BUNDLE_LIST:
      newCollectionList = { ...state.list };
      newCollectionList[action.bundle.SK].bundles = [
        ...newCollectionList[action.bundle.SK].bundles,
        action.bundle
      ];
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTION_REMOVE_FROM_BUNDLE_LIST:
      let newBundles = []
      newCollectionList = { ...state.list };
      newCollectionList[action.bundle.collection_id].bundles.forEach(bundle => {
        if(bundle.PK !== action.bundle.bundle_id) {
          newBundles.push(bundle)
        }
      })
      newCollectionList[action.bundle.collection_id].bundles = newBundles
      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTIONS_SET_A_COLLECTIONS_LABELS_GROUPS:
        newCollectionList = { ...state.list };

        let labels = {}, groups = {}
        action.objectData.forEach(objectData => {
          const { defaultLabel, goName, tags } = objectData;
          labels[goName] = defaultLabel;
          
          if(tags) {
            tags.forEach(tag => {
              if (groups.hasOwnProperty(tag)) {
                groups[tag].push(goName);
              } else {
                groups[tag] = [goName];
              }
            });
          }
        });
        newCollectionList[action.collection_id].data.map(data => {
          if(data.id === action.data_id) {
            data.labels = labels
            data.groups = groups
          }
          return data
        })

        return {
          ...state,
          list: newCollectionList
        };

    case TYPES.COLLECTIONS_ADD_MODEL_TO_COLLECTION:
        newCollectionList = { ...state.list };
        newCollectionList[action.collection_id].models[action.model_id] = action.model
        return {
          ...state,
          list: newCollectionList
        };

    case TYPES.COLLECTIONS_ADD_COLLECTION:
        return addCollection(state, action);

    default:
      return state;
  }
};
export default reducer;

const addCollection = (state, action) => {
  const {collection} = action;
  const newCollectionList = {...state.list};
  newCollectionList[collection.id] = collection;
  return {
    ...state,
    list: newCollectionList
  }
 }
