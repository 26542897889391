import React from "react";
import { Table } from "semantic-ui-react";
import EditObjectLabelItem from "./EditObjectLabel_Item";

const EditObjectsLabelList = ({
  labels,
  showingObject,
  onLabelChange,
  toggleShowObject
}) => {
  const goNames = Object.keys(labels);
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Object Name</Table.HeaderCell>
          <Table.HeaderCell>Label</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {goNames.map(goName => {
          const label = labels[goName];
          return (
            <EditObjectLabelItem
              key={goName}
              goName={goName}
              label={label}
              onLabelChange={onLabelChange}
              showingObject={showingObject}
              toggleShowObject={toggleShowObject}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default EditObjectsLabelList;
