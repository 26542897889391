import {combineReducers } from 'redux';
import collectionsReducer from "./collectionsReducer";
import User from './userReducer';
import Bundles from './bundlesReducers';
import Models from './modelReducers';
import Apps from './appsReducer';



export default combineReducers({
    collections: collectionsReducer,
    User,
    Bundles,
    Models,
    Apps
})