import React from "react";
import * as uuid from "uuid"

import {
  Input,
  Button,
  Icon,
  Table,
  Modal,
  Segment,
  Label,
  Dropdown,
  Form,
  Image,
  Message,
  Grid
} from "semantic-ui-react";
import { Languages } from "../../utils/Languages";
import { connect } from "react-redux";
import Actions from "../../actions";

class EditMeta extends React.Component {
  constructor(props) {
    super(props);

    const { name, legacyname, image, initialView } = this.props.collection;

    let titles = {};
    if (this.props.collection.title) {
      titles = { ...this.props.collection.title };
    }

    let descriptions = {};
    if (this.props.collection.description) {
      descriptions = { ...this.props.collection.description };
    }

    this.state = {
      name,
      legacyname,
      titles,
      descriptions,
      image,
      initialView,
      file: null,
      modal: null,
      somethingHasChanged: false,
      isSaving: false,
      error: false,
      errorMessage: "",
      showSaveSuccess: false
    };
  }

  componentDidUpdate(props) {

    if (this.state.reset) {
      this.props.setConfirmTabChange(false);

      const { name, legacyname, image, initialView } = props.collection;

      let titles = {};
      if (props.collection.title) {
        titles = { ...props.collection.title };
      }

      let descriptions = {};
      if (props.collection.description) {
        descriptions = { ...props.collection.description };
      }

      this.setState({
        name,
        legacyname,
        initialView,
        titles,
        descriptions,
        image,
        file: null,
        modal: null,
        somethingHasChanged: false,
        isSaving: false,
        reset: false
      })

      return
    }

    //Check for changes...
    let somethingHasChanged = this.hasSomethingChanged();

    if (somethingHasChanged !== this.state.somethingHasChanged) {
      this.setState({ somethingHasChanged });

      this.props.setConfirmTabChange(somethingHasChanged);
    }
  }

  hasSomethingChanged() {
    const { name, legacyname, titles, descriptions, image, initialView } = this.state;

    if (name !== this.props.collection.name) {
      return true;
    }

    if (legacyname !== this.props.collection.legacyname) {
      return true;
    }

    if (initialView !== this.props.collection.initialView) {
      return true;
    }

    if (image !== this.props.collection.image) {
      return true;
    }

    if (!this.compareTitleOrDesc(titles, this.props.collection.title)) {
      return true;
    }

    if (
      !this.compareTitleOrDesc(descriptions, this.props.collection.description)
    ) {
      return true;
    }

    return false;
  }

  compareTitleOrDesc(obj1, obj2) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    let theAreTheSame = true;
    const obj1_languages = Object.keys(obj1);
    obj1_languages.forEach(lang => {
      if (!obj2.hasOwnProperty(lang)) {
        theAreTheSame = false;
      } else if (obj1[lang] !== obj2[lang]) {
        theAreTheSame = false;
      }
    });

    return theAreTheSame;
  }

  ImageToBlob(file) {
    return new Promise((win, fail) => {
      let reader = new FileReader()

      reader.onload = function (e) {
        if (e.target.result) {
          win(e.target.result)
        } else {
          fail("")
        }
      };

      reader.readAsDataURL(file)
    })
  }

  //Handles all the input boxes
  onInputChange = (e, d) => {
    if (d.name === "name") {
      this.setState({ name: d.value });
    } else if (d.name === "legacyname") {
      this.setState({ legacyname: d.value });
    } else if (d.name === "modal_title") {
      let modal = { ...this.state.modal, title: d.value };
      this.setState({ modal });
    } else if (d.name === "modal_description") {
      let modal = { ...this.state.modal, description: d.value };
      this.setState({ modal });
    }
  };

  //Handles all the button clicks
  onClick = (e, { name }) => {

    switch (name) {
      case "undoNameChange":
        this.setState({ name: this.props.collection.name });
        break;

      case "undoLegacyNameChange":
        this.setState({ legacyname: this.props.collection.legacyname });
        break;

      case "undoInitialViewChange":
        this.setState({ initialView: this.props.collection.initialView });
        break;

      case "modal_deleteTitle":
        const titles_delete = { ...this.state.titles };
        delete titles_delete[this.state.modal.lang];
        this.setState({ modal: null, titles: titles_delete });
        break;

      case "modal_cancel":
        this.setState({ modal: null });
        break;

      case "modal_updateTitle":
        const titles_update = { ...this.state.titles };
        titles_update[this.state.modal.lang] = this.state.modal.title;
        this.setState({ modal: null, titles: titles_update });
        break;

      case "modal_addTitle":
        const titles_add = { ...this.state.titles };
        titles_add[this.state.modal.setLanguage] = this.state.modal.title;
        this.setState({ modal: null, titles: titles_add });
        break;

      case "modal_deleteDescription":
        const descriptions_delete = { ...this.state.descriptions };
        delete descriptions_delete[this.state.modal.lang];
        this.setState({ modal: null, descriptions: descriptions_delete });
        break;

      case "modal_updateDescription":
        const descriptions_update = { ...this.state.descriptions };
        descriptions_update[
          this.state.modal.lang
        ] = this.state.modal.description;
        this.setState({ modal: null, descriptions: descriptions_update });
        break;

      case "modal_addDescription":
        const descriptions_add = { ...this.state.descriptions };
        descriptions_add[
          this.state.modal.setLanguage
        ] = this.state.modal.description;
        this.setState({ modal: null, descriptions: descriptions_add });
        break;

      case "discard_changes":
        this.setState({ modal: null });
        this.setStateToCollection();
        break;

      case "save_changes":
        this.doSave();
        break;

      case "download_image":
        this.doFileDownload();
        break;

      case "screenshot":
        this.doScreenshot();
        break;

      case "setInitialView":
        this.setViewFromPlayer();
        break;

      case "resetView":
        this.resetPlayerView();
        break;

      default:
        console.log("Uncaught button", name);
        break;
    }
  };

  doScreenshot() {
    if (this.props.player) {
      this.props.player.takeScreenShot(imageData => {
        fetch(imageData)
          .then(res => res.blob())
          .then(async (blob) => {
            const imageURL = URL.createObjectURL(blob)
            this.setState({ image: imageURL, file: await this.ImageToBlob(blob), browsedFile: null });
          });
      });
    }
  }

  setViewFromPlayer() {
    if (this.props.player) {
      const view = { ...this.props.player.getCurrentView() };
      this.setState({ initialView: view }, () =>
        this.resetPlayerView());
    }
  }

  resetPlayerView() {
    if (this.props.player) {      
      const currentView = this.state.initialView;
      if (currentView.root_position) {
        this.props.player.setCurrentView(currentView);
      }
    }
  }

  onFileBrowseChange = async (e) => {
    var url = URL.createObjectURL(e.target.files[0]);
    this.setState({ image: url, file: await this.ImageToBlob(e.target.files[0]), screenshotFile: null });
  };

  doFileDownload = () => {
    if (this.state.image) {
      var link = document.createElement("a");
      link.href = this.state.image;
      link.download = `${this.state.name}_image.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  doSave = async () => {
    const { name, legacyname, titles, descriptions, initialView } = this.state;
    const meta = {};

    if (name !== this.props.collection.name) {
      meta.name = name;
    }

    if (legacyname !== this.props.collection.legacyname) {
      meta.legacyname = legacyname;
    }

    if (initialView !== this.props.collection.initialView) {
      meta.initialView = initialView;
    }

    if (!this.compareTitleOrDesc(titles, this.props.collection.title)) {
      meta.title = titles;
    }

    if (
      !this.compareTitleOrDesc(descriptions, this.props.collection.description)
    ) {
      meta.description = descriptions;
    }

    this.setState({ isSaving: true, error: false, errorMessage: "" });

    this.props.uploadFile(this.state.file, "Thumbnails", (newFileName) => {

      if (newFileName) {
        meta.image = newFileName
      }

      if (!newFileName && Object.keys(meta).length === 0) {
        this.setState({ isSaving: false, showSaveSuccess: true });
        return
      }

      this.props.patchMeta(this.props.collection.id, meta, resp => {
        if (resp.success) {
          this.props.setConfirmTabChange(false);
          this.setState({ showSaveSuccess: true, reset: true });
          setTimeout(() => this.setState({ showSaveSuccess: false }), 3000);
        } else {
          this.setState({ isSaving: false, error: true, errorMessage: resp.msg });
        }
      });
    })
  };

  setStateToCollection = () => {
    const { name, legacyname, initialView } = this.props.collection;

    let titles = {};
    if (this.props.collection.title) {
      titles = { ...this.props.collection.title };
    }

    let descriptions = {};
    if (this.props.collection.description) {
      descriptions = { ...this.props.collection.description };
    }

    this.setState({
      name,
      legacyname,
      initialView,
      titles,
      descriptions
    });
  };

  onDropdownChange = (e, d) => {
    if (d.name === "modal_language") {
      let modal = { ...this.state.modal, setLanguage: d.value };
      this.setState({ modal });
    }
  };

  renderNameAndLegacy = () => {
    return (
      <>
        <Input
          label="Name"
          value={this.state.name}
          name="name"
          onChange={this.onInputChange}
        />
        {this.state.name === this.props.collection.name ? null : (
          <Button
            name="undoNameChange"
            icon="undo"
            onClick={this.onClick}
            floated="right"
          />
        )}
        <br />
        <br />
        <Input
          label="Legacy Name"
          value={this.state.legacyname}
          name="legacyname"
          onChange={this.onInputChange}
        />
        {this.state.legacyname === this.props.collection.legacyname ? null : (
          <Button
            name="undoLegacyNameChange"
            icon="undo"
            onClick={this.onClick}
            floated="right"
          />
        )}
      </>
    );
  };

  renderInitialView = () => {
    return (
      <>
        <br />
        <br />

        <Button
          icon="anchor"
          content="Set Initial View"
          name="setInitialView"
          onClick={this.onClick}
        />

        <Button
          icon="undo"
          content="Reset View"
          name="resetView"
          onClick={this.onClick}
        />

        {this.state.initialView === this.props.collection.initialView ? null : (
          <Button
            name="undoInitialViewChange"
            icon="undo"
            onClick={this.onClick}
            floated="right"
          />
        )}
      </>
    );
  };

  renderTitles = () => {
    let languages = Object.keys(this.state.titles);

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Titles</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {languages.map(lang => {
            return (
              <Table.Row key={lang}>
                <Table.Cell>{Languages.GetNameFromCode(lang)}</Table.Cell>
                <Table.Cell>{this.state.titles[lang]}</Table.Cell>
                <Table.Cell>
                  <Button
                    floated="right"
                    circular
                    icon="edit"
                    onClick={() =>
                      this.setState({
                        modal: {
                          mode: "title",
                          lang,
                          title: this.state.titles[lang]
                        }
                      })
                    }
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Button
                floated="right"
                icon
                labelPosition="left"
                primary
                size="small"
                onClick={() => {
                  this.setState({
                    modal: {
                      mode: "title",
                      setLanguage: null,
                      title: ""
                    }
                  });
                }}
              >
                <Icon name="add" /> Add Title
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  };

  renderDescriptions = () => {
    let languages = Object.keys(this.state.descriptions);

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Descriptions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {languages.map(lang => {
            return (
              <Table.Row key={lang}>
                <Table.Cell>{Languages.GetNameFromCode(lang)}</Table.Cell>
                <Table.Cell>{this.state.descriptions[lang]}</Table.Cell>
                <Table.Cell>
                  <Button
                    floated="right"
                    circular
                    icon="edit"
                    onClick={() =>
                      this.setState({
                        modal: {
                          mode: "description",
                          lang,
                          description: this.state.descriptions[lang]
                        }
                      })
                    }
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Button
                floated="right"
                icon
                labelPosition="left"
                primary
                size="small"
                onClick={() => {
                  this.setState({
                    modal: {
                      mode: "description",
                      setLanguage: null,
                      description: ""
                    }
                  });
                }}
              >
                <Icon name="add" /> Add Description
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  };

  renderImage = () => {
    const downloadDisable = this.state.image ? false : true;
    return (
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Image
                src={
                  this.state.image
                    ? this.state.image
                    : "https://react.semantic-ui.com/images/wireframe/image.png"
                }
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Button.Group vertical labeled icon>
                <Button
                  icon="image"
                  content="Screenshot"
                  name="screenshot"
                  onClick={this.onClick}
                />
                <Button
                  icon="upload"
                  content="Browse"
                  name="upload_image"
                  as="label"
                  htmlFor="file"
                  type="button"
                />
                <Button
                  icon="download"
                  content="Download"
                  name="download_image"
                  disabled={downloadDisable}
                  onClick={this.onClick}
                />
                <input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  accept="image/png"
                  onChange={this.onFileBrowseChange}
                />
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  renderSaveDiscard = () => {
    const disabled = !this.state.somethingHasChanged;
    return (
      <Segment basic>
        <Button
          negative
          disabled={disabled}
          name="discard_changes"
          onClick={() =>
            this.setState({
              modal: { mode: "confirm_discard" }
            })
          }
        >
          Discard Changes
        </Button>
        <Button
          positive
          disabled={disabled}
          name="save_changes"
          onClick={this.onClick}
          loading={this.state.isSaving}
        >
          Save Changes
        </Button>
        {this.state.showSaveSuccess ? (
          <Label basic color="green" pointing="left">
            Saved!
          </Label>
        ) : null}
      </Segment>
    );
  };

  renderModal = () => {
    if (this.state.modal === null) {
      return null;
    }

    const { mode, lang } = this.state.modal;

    if (mode === "title") {
      if (lang) {
        return this.renderModal_EditTitle();
      } else {
        return this.renderModal_NewTitle();
      }
    } else if (mode === "description") {
      if (lang) {
        return this.renderModal_EditDescription();
      } else {
        return this.renderModal_NewDescription();
      }
    } else if (mode === "confirm_discard") {
      return this.renderModal_ConfirmDiscard();
    }
  };

  renderModal_ConfirmDiscard = () => {
    return (
      <Modal open size="tiny">
        <Modal.Header>Discard Changes?</Modal.Header>
        <Modal.Content>
          Are you sure you want to discard all changes?
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="discard_changes"
            negative
            icon="trash"
            labelPosition="right"
            content="Yes, Discard Changes"
            onClick={this.onClick}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderModal_EditTitle = () => {
    //Editing an existing title
    const { lang, title } = this.state.modal;
    let saveDisabled = title ? false : true;
    return (
      <Modal open size="tiny">
        <Modal.Header>
          Edit {Languages.GetNameFromCode(lang)} Title
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Input
                label="Title"
                value={title}
                name="modal_title"
                onChange={this.onInputChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_deleteTitle"
            floated="left"
            negative
            onClick={this.onClick}
          >
            Delete
          </Button>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="modal_updateTitle"
            positive
            icon="checkmark"
            labelPosition="right"
            content="Update"
            onClick={this.onClick}
            disabled={saveDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderModal_NewTitle = () => {
    //Adding a new language title
    const { setLanguage, title } = this.state.modal;
    let saveDisabled = setLanguage && title ? false : true;

    let langAlreadyUsed = false;
    if (setLanguage && this.state.titles.hasOwnProperty(setLanguage)) {
      langAlreadyUsed = true;
      saveDisabled = true;
    }
    return (
      <Modal open size="tiny">
        <Modal.Header>Add Title</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Dropdown
                name="modal_language"
                placeholder="Language"
                search
                selection
                options={Languages.GetLanguagesForDropdown()}
                onChange={this.onDropdownChange}
              />
              {langAlreadyUsed ? (
                <Label pointing prompt>
                  {`This collection already has an ${Languages.GetNameFromCode(
                    setLanguage
                  )} title`}
                </Label>
              ) : null}
            </Form.Field>
            <Form.Field>
              <Input
                label="Title"
                value={this.state.modal.title}
                name="modal_title"
                onChange={this.onInputChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="modal_addTitle"
            positive
            icon="add"
            labelPosition="right"
            content="Add"
            onClick={this.onClick}
            disabled={saveDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderModal_EditDescription = () => {
    const { description, lang } = this.state.modal;
    let saveDisabled = description ? false : true;
    return (
      <Modal open size="tiny">
        <Modal.Header>
          Edit {Languages.GetNameFromCode(lang)} Description
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.TextArea
              label="Description"
              value={description}
              name="modal_description"
              onChange={this.onInputChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_deleteDescription"
            floated="left"
            negative
            onClick={this.onClick}
          >
            Delete
          </Button>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="modal_updateDescription"
            positive
            icon="checkmark"
            labelPosition="right"
            content="Update"
            onClick={this.onClick}
            disabled={saveDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderModal_NewDescription = () => {
    const { setLanguage, description } = this.state.modal;

    let saveDisabled = setLanguage && description ? false : true;

    let langAlreadyUsed = false;
    if (setLanguage && this.state.descriptions.hasOwnProperty(setLanguage)) {
      langAlreadyUsed = true;
      saveDisabled = true;
    }
    return (
      <Modal open size="tiny">
        <Modal.Header>Add Description</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Dropdown
                name="modal_language"
                placeholder="Language"
                search
                selection
                options={Languages.GetLanguagesForDropdown()}
                onChange={this.onDropdownChange}
              />
              {langAlreadyUsed ? (
                <Label pointing prompt>
                  {`This collection already has an ${Languages.GetNameFromCode(
                    setLanguage
                  )} description`}
                </Label>
              ) : null}
            </Form.Field>
            <Form.TextArea
              label="Description"
              value={description}
              name="modal_description"
              onChange={this.onInputChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="modal_cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.onClick}
          />
          <Button
            name="modal_addDescription"
            positive
            icon="add"
            labelPosition="right"
            content="Add"
            onClick={this.onClick}
            disabled={saveDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  render_Error() {
    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>Uh Oh!</Message.Header>
          <p>{this.state.errorMessage}</p>
        </Message>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <>
        {this.render_Error()}
        {this.renderSaveDiscard()}
        {this.renderImage()}
        {this.renderNameAndLegacy()}
        {this.renderInitialView()}
        {this.renderTitles()}
        {this.renderDescriptions()}
        {this.renderModal()}
      </>
    );
  }
}

export default connect(null, {
  patchMeta: (id, meta, callback) => {
    return Actions.Collections.patchCollectionMeta(id, meta, callback)
  },
  uploadFile: (file, folder, cb) => {
    if (file) {
      const filename = `${uuid.v4()}.thumbnail`
      return Actions.Universal.uploadFile({ file, folder, filename }, cb)
    } else {
      cb("")
      return { type: "NO ACTION" }
    }
  }
})(EditMeta);
