import Actions from "../actions/model";
import { call, put } from "redux-saga/effects";

export const getPostModelUrl = requestModule =>
  function* getPostModelUrlSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `S3/PreSign/ModelVariants/${action.filename}/putObject`,
        method: "GET"
      });

      yield put(Actions.postModelToS3(req.data, action.modelData, action.callback));

    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };

export const uploadModel = customRequestModule =>
  function* uploadModelSaga(action) {
    console.log(action.modelData)
    try {
      console.log("HERE", {
        url: action.url,
        method: 'PUT',
        data: action.modelData,
        allowHeaders: false,
        expectingResponse: false
      })
      yield call(customRequestModule, {
        url: action.url,
        method: 'PUT',
        data: action.modelData,
        allowHeaders: false,
        expectingResponse: false
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err.message });
      } else {
        throw err;
      }
    }
  };