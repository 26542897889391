import Collections from './collections';
import User from './users';
import Bundles from "./bundles"
import Universal from "./universal"
import Model from "./model"
import Apps from "./apps"



const Actions = {
    Collections,
    User,
    Bundles,
    Universal,
    Model,
    Apps
}

export default Actions;