import React from "react";
import PlayerCanvas from "../PlayerCanvas/PlayerCanvas";
import { Segment, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import Actions from "../../actions";
import WebGLPlayer from "../../player/WebGLPlayer";

const playerCanvasID = "webGL_Canvas";

class EditCollection_Player extends React.Component {
  player = null;

  constructor(props) {
    super(props);

    let modelList = [];

    const collectionModels = Object.values(this.props.collection.models);
    collectionModels.forEach(model => {
      if (model.format === "glb") {
        modelList.push(model);
      }
    });

    this.state = {
      modelList,
      isFetchingModel: false,
      showingModel: null,
      error: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    if (this.state.modelList.length > 0) {
      this.showModel(this.state.modelList[0]);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  showModel(model) {
    if (model.url) {
      this.makePlayerAndLoadModel(model.url);
      this.setState({ showingModel: model });
    } else {
      this.setState({ isFetchingModel: true });
      this.props.getCollectionModelURL(
        this.props.collection.id,
        model.id,
        model.filename,
        resp => {
          if (resp.success) {
            this.setState({ showingModel: model });
            this.makePlayerAndLoadModel(resp.url);
          } else {
            this.setState({
              isFetchingModel: false,
              error: true,
              errorMessage: resp.msg
            });
          }
        }
      );
    }
  }

  makePlayerAndLoadModel(urlToModel) {
    if (this.player) {
      this.player.dispose();
    }
    this.player = new WebGLPlayer(document.getElementById(playerCanvasID));
    this.props.registerPlayerCallback(this.player);
    this.player.loadGLBFromURL(
      urlToModel,
      () => {
        //Success
        this.setState({ isFetchingModel: false });

        if (!this.props.collection.objectList) {
          //Make our list of object
          const objList = [];
          this.player.collectionObjects.objectLookup.forEach((t, objName) => {
            objList.push(objName);
          });
          this.props.setCollectionObjectList(this.props.collection.id, objList);
        }

        // initial view
        if (this.props.collection.initialView && this.props.collection.initialView.root_position) {
          this.player.setCurrentView(this.props.collection.initialView);
        }
      },
      errorMsg => {
        //Fail
        this.setState({
          error: true,
          errorMessage: errorMsg
        });
      }
    );
  }

  render() {
    return (
      <Segment loading={this.state.isFetchingModel}>
        {this.state.modelList.length === 0 ? (
          <Message
            icon="cube"
            header="Cannot display any models for this Collection"
          />
        ) : null}
        {this.state.error ? (
          <Message
            negative
            icon="exclamation"
            header="Uh oh!"
            content={this.state.errorMessage}
          />
        ) : null}
        <PlayerCanvas canvasId={playerCanvasID} />
      </Segment>
    );
  }
}

export default connect(null, {
  getCollectionModelURL: (collectionId, modelId, filename, callback) =>
    Actions.Collections.getCollectionModelURL(
      collectionId,
      modelId,
      filename,
      callback
    ),
  setCollectionObjectList: (collectionId, objectList) =>
    Actions.Collections.setCollectionObjectList(collectionId, objectList)
})(EditCollection_Player);
