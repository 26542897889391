import React from "react";
import { Icon, Message, Label, Header } from "semantic-ui-react";
import "./ListAllApps.css";
import { connect } from "react-redux";
import Actions from "../../actions";

class ListAllApps extends React.Component {
  state = {
    error: false,
    errorMessage: false,
    isLoading: true
  };

  setError = message => {
    this.setState({ error: true, errorMessage: message });
  };

  componentDidMount(){
    this.props.getAllApps((result) => {
      if(result.success) {
        this.setState({ isLoading: false })
      }
    })
  }

  renderAllApps() {
    let apps = this.props.apps,
    appComps = apps.map(app => {
      return (
        <div id="app_list_item" key={Math.random()}>
          <Label size='big'>
            Id - {app.PK}
          </Label>
          <Label size='big'>
            Title - {app.title}
          </Label>
          <Label size='big'>
            Description - {app.description}
          </Label>
        </div>
      )
    })

    return appComps
  }

  render_loader() {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          We are fetching all Apps...
        </Message.Content>
      </Message>
    )
  }

  render() {
    console.log(this.state.isLoading)
    return (
      <div id="list_container">
      <Header as='h2'>All Apps</Header>
      {this.state.isLoading ? this.render_loader() : this.renderAllApps()}
      </div>
    );
  }
}

const MapStateToProps = state => {
  return {
    apps: state.Apps.list
  };
};

export default connect(MapStateToProps, {
  getAllApps: (callback) => Actions.Apps.getAllApps(callback),
})(ListAllApps);

