import { Vector3, Quaternion } from "@babylonjs/core/Maths/math";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";

export class LoadLocalGLBFile {
  _OnSuccessCallback;
  _OnFailCallback;
  _player;

  constructor(data, player, OnSuccessCB, OnFailCB) {
    this._OnFailCallback = OnFailCB;
    this._OnSuccessCallback = OnSuccessCB;
    this._player = player;
    this._doImport(data);
  }

  _doImport(data) {
    let blob = new Blob([data]);
    var url = URL.createObjectURL(blob);
    
    SceneLoader.ImportMesh(
      "", //Mesh names
      "",
      url, //asset
      this._player.scene, //Scene
      objects => {
        //success
        this._processModel(objects);
      },
      () => {}, //Progress callback
      e => {
          console.log(e);
        this._OnFailCallback("Could not import the model: " + e);
      },
      ".glb" //Import plugin extention
    );
  }

  /*
  The end heirarchy should look like this
  - Collection Root (not included in the glb)
    -This Collection's root (actual root of this collection)
      -Child Transform
        -Child Mesh
      -Child Transform
        -Child Mesh
        -Child Mesh
        ...
      ...
  */

  _processModel(objects) {
    const root = objects[0];
    let importedCollectionRoot = root;
    while (importedCollectionRoot.name === "__root__") {
      importedCollectionRoot = importedCollectionRoot.getChildTransformNodes()[0];

      if (importedCollectionRoot == null) {
        this._OnFailCallback("Failed to find collection root");
        return;
      }
    }
    this._player.collectionRoot.reset();
    importedCollectionRoot.setParent(this._player.collectionRoot.transform);
    importedCollectionRoot.computeWorldMatrix();
    root.dispose();

    // get children transforms
    let children = importedCollectionRoot.getChildTransformNodes(true);

    //We need to ensure there are no duplicate names
    let childNames = [];
    //Setup each child
    for (let i = 0; i < children.length; i++) {
      let child = children[i];

      if (childNames.includes(child.name)) {
        //Bummer!
        this._OnFailCallback(`Found a duplicate child name in the file: ${child.name}`);
        return;
      }
      childNames.push(child.name);

      let childClass = child.getClassName();

      if (childClass === "TransformNode") {
        //This child is a split mesh object, meaning this object has numerous meshes. This is caused by multiple
        //materials or large meshes. We just need to make sure that any scaling is applied to the meshes, not the child transform
        let transformScale = child.scaling.clone();
        child.scaling = Vector3.One();

        //Make sure it has a quaterion for rotation
        if (!child.rotationQuaternion) {
          let eul = child.rotation;
          child.rotationQuaternion = Quaternion.RotationYawPitchRoll(
            eul.y,
            eul.x,
            eul.z
          );
        }
        child.computeWorldMatrix();

        let subMeshes = child.getChildTransformNodes(true);
        subMeshes.forEach(mesh => {
          mesh.scaling = transformScale.clone();
          mesh.computeWorldMatrix();
        });
      } else if (childClass === "Mesh") {
        //This child is just a mesh. We need to make a transform parent for it
        let newTransformNode = new TransformNode(child.name);
        newTransformNode.parent = child.parent;
        newTransformNode.position = child.position.clone();
        newTransformNode.scaling = Vector3.One();
        if (child.rotationQuaternion) {
          newTransformNode.rotationQuaternion = child.rotationQuaternion.clone();
        } else {
          let eul = child.rotation;
          newTransformNode.rotationQuaternion = Quaternion.RotationYawPitchRoll(
            eul.y,
            eul.x,
            eul.z
          );
        }
        newTransformNode.computeWorldMatrix();
        child.setParent(newTransformNode);
        child.computeWorldMatrix();
      } else {
        //If we hit this then we have run unto an child with an unexpected class.
        this._OnFailCallback(`Ran into an unexpected class while processing the mesh: ${childClass}`);
        return;
      }
    }
    this._OnSuccessCallback(importedCollectionRoot);
  }
}
