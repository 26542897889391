import * as TYPES from "./types";

const getAllBundles = (callback) => {
    return{
        type: TYPES.BUNDLES_GET_ALL,
        callback
    };
}

const setBundleList = (bundles) => {
    return{
        type: TYPES.BUNDLES_SET_LIST,
        bundles
    };
}

const clearBundleList = () => {
    return{
        type: TYPES.BUNDLES_CLEAR_LIST
    };
}

const addCollectionToBundle = (collection_id, bundles, callback) => {
    return{
        type: TYPES.ADD_COLLECTION_TO_BUNDLE,
        callback,
        bundles,
        collection_id
    };
}

const removeCollectionToBundle = (collection_id, bundles, callback) => {
    return{
        type: TYPES.REMOVE_COLLECTION_TO_BUNDLE,
        callback,
        bundles,
        collection_id
    };
}

export default {
    getAllBundles,  
    setBundleList,
    clearBundleList,
    addCollectionToBundle,
    removeCollectionToBundle
}