import React from "react";
import {
  Message,
  Icon,
  Menu,
  Segment,
  Modal,
  Button,
  Grid
} from "semantic-ui-react";
import { connect } from "react-redux";
import LoginState from "../components/LoginState/LoginState";
import Actions from "../actions";
import EditOverview from "../components/Edit/EditOverview";
import EditMeta from "../components/Edit/EditMeta";
import EditData from "../components/Edit/EditData";
import EditModel from "../components/Edit/EditModel";
import EditCollectionPlayer from "../components/Edit/EditCollection_Player";

class EditCollection extends React.Component {
  state = {
    error: false,
    errorMessage: "",
    showInspector: false,
    showConfirmTabChange: false,
    fullyLoaded: false,
    modal: null,
    player: null
  };

  registerPlayer = player => {
    this.setState({ player });
  };

  handleItemClick = (e, { name }) => {
    if (this.state.showConfirmTabChange) {
      //Show the modal
      this.setState({
        modal: {
          mode: "confirm_tab_change",
          desitnation: name
        }
      });
    } else {
      //Just do it bro
      this.setState({ activeItem: name });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `tab=${name}`
      });
    }
  };

  setConfirmTabChange = showConfirm => {
    this.setState({ showConfirmTabChange: showConfirm });
  };

  componentDidMount() {
    if (!this.state.fullyLoaded) {
      this.props.fetchCollection(this.props.collection_id, result => {
        if(result.success) {
          this.setState({fullyLoaded: true});
        } else {
          this.setState({
            error: true,
            errorMessage: result.msg
          });
        }
      });
    }
  }  

  renderModal = () => {
    if (!this.state.modal) {
      return null;
    } else {
      const { mode } = this.state.modal;

      if (mode === "confirm_tab_change") {
        return (
          <Modal open size="tiny">
            <Modal.Header>Discard Changes?</Modal.Header>
            <Modal.Content>
              Are you sure you want to change tabs? This will discard any
              changes you have made.
            </Modal.Content>
            <Modal.Actions>
              <Button
                labelPosition="right"
                content="Cancel"
                onClick={() => {
                  this.setState({ modal: null });
                }}
              />
              <Button
                negative
                icon="trash"
                labelPosition="right"
                content="Yes, Discard Changes"
                onClick={() => {
                  this.setState({
                    modal: null,
                    activeItem: this.state.modal.desitnation,
                    showConfirmTabChange: false
                  });
                }}
              />
            </Modal.Actions>
          </Modal>
        );
      }
    }
  };

  renderRightPanel() {
    if (this.state.showInspector) {
      return null;
    } else {
      const activeItem  = this.props.currentTab;
      return (
        <Grid.Column>
          <Menu attached="top" tabular>
            <Menu.Item
              name="Overview"
              active={activeItem === "Overview"}
              onClick={this.handleItemClick}
            ></Menu.Item>
            <Menu.Item
              name="Meta"
              active={activeItem === "Meta"}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              name="Data"
              active={activeItem === "Data"}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              name="Models"
              active={activeItem === "Models"}
              onClick={this.handleItemClick}
            />
          </Menu>
          <Segment attached="bottom">
            {activeItem === "Overview" ? (
              <EditOverview collection={this.props.collection} />
            ) : null}
            {activeItem === "Meta" ? (
              <EditMeta
                collection={this.props.collection}
                setConfirmTabChange={this.setConfirmTabChange}
                player={this.state.player}
              />
            ) : null}
            {activeItem === "Data" ? (
              <EditData
                collection={this.props.collection}
                setConfirmTabChange={this.setConfirmTabChange}
                player={this.state.player}
              />
            ) : null}
            {activeItem === "Models" ? (
              <EditModel
                collection={this.props.collection}
                setConfirmTabChange={this.setConfirmTabChange}
                player={this.state.player}
              />
            ) : null}
          </Segment>
        </Grid.Column>
      );
    }
  }

  renderLeftPanel = () => {
    return (
      <Grid.Column>
        <EditCollectionPlayer
          collection={this.props.collection}
          registerPlayerCallback={this.registerPlayer}
        />
      </Grid.Column>
    );
  };

  render() {
    if (this.props.loginStatus === "isLoggedIn") {
      if (this.state.error) {
        return (
          <Message negative>
            <Message.Header>Uh oh...</Message.Header>
            <p>{this.state.errorMessage}</p>
          </Message>
        );
      } else if (!this.state.fullyLoaded) {
        return (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Just one second</Message.Header>
              We are fetching that collections for you...
            </Message.Content>
          </Message>
        );
      } else {
        return (
          <>
            <Grid columns={2}>
              {this.renderLeftPanel()}
              {this.renderRightPanel()}
            </Grid>
            {this.renderModal()}
          </>
        );
      }
    } else {
      return <LoginState loginStatus={this.props.loginStatus} />;
    }
  }
}

const MapStateToProps = (state, passedProps) => {
  const loginStatus = state.User.loginStatus;
  const collection_id = passedProps.match.params.collection_id;

  let collection = null;
  if (state.collections.list.hasOwnProperty(collection_id)) {
    collection = state.collections.list[collection_id];
  }

  const searchParams = new URLSearchParams(passedProps.location.search);
  const currentTab=searchParams.has("tab") ? searchParams.get("tab") : "Overview";

  return {
    collection_id,
    loginStatus,
    collection,
    currentTab
  };
};

export default connect(MapStateToProps, {
  fetchAllCollections: callback => {
    return Actions.Collections.fetchAllCollections(callback);
  },
  fetchCollection: (collection, callback) => {
    return Actions.Collections.fetchACollection(collection, callback);
  }
})(EditCollection);
