import React from "react";
import { Table, Button } from "semantic-ui-react";
import StatusLabel from "../Status/StatusLabel";

const ModelListItem = props => {
  const {
    filename,
    version,
    status,
  } = props.model;

  const {showDownloadSpinner} = props;

  return (
    <Table.Row key={filename}>
      <Table.Cell>{version}</Table.Cell>
      <Table.Cell>
        <StatusLabel status={status}/>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button
          circular
          icon="edit"
          onClick={() => props.clickEditModel(props.model)}
        />
        <Button
          circular
          icon="download"
          onClick={() => props.clickDownloadModel(props.model)}
          loading={showDownloadSpinner}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ModelListItem;
