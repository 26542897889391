import React from "react";
import { Card, Image, Message, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import Actions from "../actions";
import LoginState from "../components/LoginState/LoginState";
import { Link } from "react-router-dom";

class Collections extends React.Component {
  state = {
    isFetching: false, //Lets us know if we are waiting on the API
    error: false, //Lets us know that something went wrong
    errorMessage: "" //A message about what went wring
  };

  componentDidUpdate() {
    if (
      this.props.loginStatus === "isLoggedIn" &&
      !this.props.allHaveBeenRetrieved &&
      !this.state.isFetching
    ) {
      this.doFetchAllCollections();
    }
  }

  componentDidMount() {
    if (!this.props.allHaveBeenRetrieved && this.props.loginStatus === "isLoggedIn") {
      this.doFetchAllCollections();
    } else {
      this.setState({ isFetching: false });
    }
  }

  doFetchAllCollections() 
  {
    this.props.fetchAllCollections(resp => {
      if (resp.success) {
        this.setState({ isFetching: false });
      } else {
        this.setState({
          isFetching: false,
          error: true,
          errorMessage: resp.msg
        });
      }
    });
    this.setState({ isFetching: true });
  }

  getNextCollectionList = () => {
    if (this.state.isFetching) {
      // already fetching more
      return;
    }

    this.setState({ isFetching: true });
    this.fetchCollectionBundle(this.props.collectionsRetrieved);
  };

  fetchCollectionBundle = collectionsRetrieved => {
    this.setState({ isFetching: true });

    this.props.fetchCollectionBundle(
      "bb5d39f4-94ab-4658-a37f-83aa80386688",
      collectionsRetrieved,
      25,
      resp => {
        if (resp.success) {
          this.setState({ isFetching: false });
        } else {
          this.setState({
            isFetching: false,
            error: true,
            errorMessage: resp.msg
          });
        }
      }
    );
  };

  renderCardList() {
    return this.props.collections.map(collection => {

      let title = "No Title";
      if(collection.title)
      {
        title = collection.title.en ? collection.title.en : "No English Title";
      };
      let description = "";
      if(collection.description)
      {
        description = collection.description.en ? collection.description.en : "description";
      }
      return (
        <Card key={collection.id} as={Link} to={`/collections/${collection.id}`}>
          <Card.Content>
            <Image floated="right" size="mini" src={collection.image} />
            <Card.Header>{title}</Card.Header>
            <Card.Meta>{collection.name ? collection.name : "No Name"}</Card.Meta>
            <Card.Description>{description}</Card.Description>
          </Card.Content>
        </Card>
      );
    });
  }

  renderFetchingMessage() {
    if (this.state.isFetching) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            We are fetching more collections for you...
          </Message.Content>
        </Message>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.props.loginStatus === "isLoggedIn") {
      return (
        <>
          <Card.Group>{this.renderCardList()}</Card.Group>
          {this.renderFetchingMessage()}
        </>
      );
    } else {
      return <LoginState loginStatus={this.props.loginStatus} />;
    }
  }
}

const MapStateToProps = state => {
  const { allHaveBeenRetrieved } = state.collections;
  const loginStatus = state.User.loginStatus;

  return {
    loginStatus,
    collections: Object.values(state.collections.list),
    allHaveBeenRetrieved
  };
};

export default connect(MapStateToProps, {
  fetchAllCollections: callback =>
    Actions.Collections.fetchAllCollections(callback)
})(Collections);
