import * as TYPES from "../actions/types";

const initialState = {
  list: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.BUNDLES_SET_LIST:
      return { ...state, list: [...action.bundles] };

    case TYPES.BUNDLES_CLEAR_LIST:
      return { ...state, list: [] };

    default:
      return state;
  }
};

export default reducer;
