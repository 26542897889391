import React from "react";
import { Message, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const LoginState = props => {
    if (props.loginStatus === "isLogginIn" || props.loginStatus === "init") {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          Checking for login
        </Message.Content>
      </Message>
    );
  } else if (props.loginStatus === "isNotLoggedIn") {
    return (
      <>
        <Message negative>
          <Message.Header>Uh oh!</Message.Header>
          <p>You must be logged in</p>
          <Button as={Link} to="/user">
            Go to login
          </Button>
        </Message>
      </>
    );
  } else {
    return <></>;
  }
};

export default LoginState;