import React from "react";
import { Label } from "semantic-ui-react";

export const LODLabel = props => {
  const { lod } = props;
  return (
    <Label color={LODColor(lod)} basic>
      {LODString(lod)}
    </Label>
  );
};

export const LODColor = (lod) => {
    const _lod = lod.toLowerCase();

    let color = "gray";
    if (_lod === "ld") {
        color = "olive";
    } else if (_lod === "sd") {
        color = "orange";
    } else if (_lod === "hd") {
        color = "green";
    }
    return color;
}

export const LODString = (lod) => {
    const _lod = lod.toLowerCase();

    let label = "UNKNOWN";
    if (_lod === "ld") {
        label = "LD";
    } else if (_lod === "sd") {
        label = "SD";
    } else if (_lod === "hd") {
        label = "HD";
    }
    return label;
}
