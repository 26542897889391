import React from "react";
import { Label } from "semantic-ui-react";

const StatusLabel = props => {
  let color = "grey";
  let text = "UNKNOWN";

  switch (props.status) {
    case "published":
      color = "pink";
      text = "PUBLISHED";
      break;

    case "review":
      color = "yellow";
      text = "REVIEW";
      break;

    case "draft":
      color = "purple";
      text = "DRAFT";
      break;
    default:
      break;
  }

  return (
    <Label color={color} horizontal>
      {text}
    </Label>
  );
};

export default StatusLabel;
